import * as React from "react";
import { init as initApm } from "@elastic/apm-rum";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.css";
import { config } from "./config";
import { routes } from "./App";
import { ThemeProvider } from "./ThemeProvider";
import { Fallback } from "./Fallback";

initApm({
  serviceName: "nomnom-web",
  serverUrl: "https://apm.conc.at",
  serviceVersion: "0.1",
  environment: import.meta.env.MODE,
  distributedTracing: true,
  distributedTracingOrigins: [config.url.base],
});

const router = createBrowserRouter(routes);

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
    <RouterProvider router={router} fallbackElement={<Fallback />} />
  </ThemeProvider>,
);
