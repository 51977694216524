import React from "react";

import { HeadingM, HeadingXL } from "./components/Heading";

export function Fallback() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <HeadingXL>Welcome back!</HeadingXL>
      <div className="flex gap-3 pb-10">
        <div
          className="inline-block h-6 w-6 animate-spin rounded-full border-[1.5px] border-current border-t-transparent text-foreground"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <HeadingM>Loading your nomnoms...</HeadingM>
      </div>
    </div>
  );
}
