import React, { createContext, useContext, useState } from "react";

export type Page = "home" | "add-recipe";

interface CommandPaletteState {
  open: boolean;
  pages: Page[];
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: CommandPaletteState = {
  open: false,
  pages: ["home"],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPages: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpen: () => {},
};

const CommandPaletteContext = createContext<CommandPaletteState>(initialState);

export function CommandPaletteProvider(props: React.PropsWithChildren) {
  const [pages, setPages] = useState(initialState.pages);
  const [open, setOpen] = useState(initialState.open);

  const value = {
    ...initialState,
    pages,
    open,
    setPages,
    setOpen,
  };

  return <CommandPaletteContext.Provider value={value} {...props} />;
}

export function useCommandPalette() {
  const { setPages, setOpen, pages, open } = useContext(CommandPaletteContext);

  const openPalettePage = (pages: Page[]) => {
    setPages(pages);
    setOpen(true);
  };

  return { pages, setOpen, setPages, openPalettePage: openPalettePage, open };
}
