import * as React from "react";
import { Link } from "react-router-dom";
import { RecipeDocument } from "db";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/Card";
// import { Badge } from "../../components/Badge";
import { Skeleton } from "../../components/Skeleton";
import { AddIcon } from "../../components/Icons";
import { Button } from "../../components/Button";
import { cn } from "../../utilities";
import { Source } from "../Source";

import { Image } from "./components/Image";

interface RecipeCardProps {
  recipe: RecipeDocument;
}

export function RecipeCard({ recipe }: RecipeCardProps) {
  const isPending = !["success", "error"].includes(recipe.status || "pending");

  return (
    <Link to={`/recipes/${recipe.id}`}>
      <Card className="group flex h-full flex-col overflow-hidden transition-colors hover:hover:bg-accent/60">
        <div className="h-[180px] overflow-hidden">
          {recipe.imageUrl?.[0] && (
            <Image
              alt={recipe.name}
              blurhash={recipe.blurhash}
              className="group-hover:scale-105"
              loading="lazy"
              referrerPolicy="no-referrer"
              src={recipe?.imageUrl?.[recipe?.imageUrl?.length - 1]}
            />
          )}
        </div>
        <div className="flex flex-grow flex-col">
          <div>
            <CardHeader className="px-5 pb-2 pt-5">
              <CardTitle className="line-clamp-2 min-h-[47px] pb-0.5 font-serif text-lg font-semibold leading-tight">
                {recipe.name && recipe.name}
                {isPending && <Skeleton className="h-6 w-[150px]" />}
              </CardTitle>
            </CardHeader>
          </div>
          <div className="flex flex-grow flex-col justify-between">
            <CardContent className="space-y-2 px-5 pb-2">
              {isPending && (
                <CardDescription className="line-clamp-2">
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-[250px]" />
                    <Skeleton className="h-4 w-[200px]" />
                  </div>
                </CardDescription>
              )}
              <CardDescription
                className="line-clamp-2"
                dangerouslySetInnerHTML={{
                  __html: recipe.description || "",
                }}
              />
              {/* {recipe.tags && (
                <div className="flex gap-1">
                  {recipe.tags.map((tag) => (
                    <Badge key={`${recipe.name}${tag}`} variant="secondary">
                      {tag}
                    </Badge>
                  ))}
                </div>
              )} */}
            </CardContent>
            <CardFooter className="px-5 pb-4">
              {recipe.url && (
                <Source icon={recipe.website?.icons?.[0]} url={recipe.url} />
              )}
            </CardFooter>
          </div>
        </div>
      </Card>
    </Link>
  );
}

export function NoRecipeCard({
  className,
  onClick,
}: React.HTMLAttributes<HTMLDivElement> &
  Pick<React.HTMLAttributes<HTMLButtonElement>, "onClick">) {
  return (
    <Card
      className={cn(
        "group relative flex h-full flex-col overflow-hidden transition-colors",
        onClick && "hover:hover:bg-accent/60",
        className,
      )}
    >
      <div className="h-[180px] overflow-hidden">
        <Skeleton className="h-[180px] w-full rounded-none" noAnimation />
      </div>
      <div className="flex flex-grow flex-col">
        <div>
          <CardHeader className="px-5 pb-2 pt-5">
            <CardTitle className="line-clamp-2 min-h-[42px] pb-0.5 font-serif text-lg font-semibold leading-tight">
              <Skeleton className="h-6 w-[150px]" noAnimation />
            </CardTitle>
          </CardHeader>
        </div>
        <div className="flex flex-grow flex-col justify-between">
          <CardContent className="space-y-2 px-5 pb-2">
            <CardDescription className="line-clamp-2">
              <div className="space-y-2">
                <Skeleton className="h-4 w-[250px]" noAnimation />
                <Skeleton className="h-4 w-[200px]" noAnimation />
              </div>
            </CardDescription>
          </CardContent>
          <CardFooter className="px-5 pb-4">
            <Skeleton className="h-4 w-[200px]" noAnimation />
          </CardFooter>
        </div>
      </div>
      {onClick && (
        <div className="absolute hidden h-full w-full items-center justify-center group-hover:flex">
          <Button onClick={onClick}>
            <AddIcon /> Add recipe
          </Button>
        </div>
      )}
    </Card>
  );
}
