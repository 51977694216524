import { useEffect, useState } from "react";
import { Params, useLoaderData } from "react-router-dom";
import type {
  AnnotationDocument,
  AnnotationDocumentQuery,
  RecipeDocument,
  RecipeEventDocument,
  RxQuery,
} from "db";

import { getDb } from "../../db";

export const loader = async function ({ params }: { params: Params<string> }) {
  const db = await getDb();

  const recipeQuery = await db.recipes.findOne({ selector: { id: params.id } });
  const recipeEventsQuery = db["recipe-events"].find({
    selector: { recipeId: params.id },
    sort: [{ addedAt: "desc" }],
  });
  const annotationsQuery = db.annotations.find({
    selector: { recipeId: params.id },
  });

  const [recipe, recipeEvents, annotations] = await Promise.all([
    recipeQuery.exec(),
    recipeEventsQuery.exec(),
    annotationsQuery.exec(),
  ]);

  return {
    recipe,
    annotations,
    annotationsQuery,
    recipeEvents,
    recipeEventsQuery,
  };
};

export const useRecipeDocumentDataLoader = function (): {
  recipe: RecipeDocument;
  annotations: AnnotationDocument[];
  recipeEvents: RecipeEventDocument[];
} {
  const {
    recipe: initialRecipe,
    annotations: initialAnnotations,
    annotationsQuery,
    recipeEvents: initialRecipeEvents,
    recipeEventsQuery,
  } = useLoaderData() as {
    recipe: RecipeDocument;
    annotations: AnnotationDocument[];
    annotationsQuery: AnnotationDocumentQuery;
    recipeEvents: RecipeEventDocument[];
    recipeEventsQuery: RxQuery<RecipeEventDocument, RecipeEventDocument[]>;
  };

  const [recipe, setRecipe] = useState(initialRecipe);
  const [recipeEvents, setRecipeEvents] = useState(initialRecipeEvents);
  const [annotations, setAnnotations] = useState(initialAnnotations);

  useEffect(() => {
    const recipeSubscription = initialRecipe.$.subscribe(
      (result: RecipeDocument) => {
        setRecipe(result);
      },
    );

    return () => {
      recipeSubscription.unsubscribe();
    };
  }, [initialRecipe]);

  useEffect(() => {
    const recipeEventsQuerySubscription = recipeEventsQuery.$.subscribe(
      (events) => {
        setRecipeEvents(events);
      },
    );

    return () => {
      recipeEventsQuerySubscription.unsubscribe();
    };
  }, [recipeEventsQuery]);

  useEffect(() => {
    const annotationsQuerySubscription = annotationsQuery.$.subscribe(
      (result) => {
        setAnnotations(result);
      },
    );

    return () => {
      annotationsQuerySubscription.unsubscribe();
    };
  }, [annotationsQuery]);

  return { recipe, annotations, recipeEvents };
};
