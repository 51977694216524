import * as React from "react";
import { useState } from "react";
import { RecipeDocument } from "db";

import { Button } from "../../../components/Button";
import {
  AddIcon,
  CalcServingsIcon,
  CloseLeftSidebarIcon,
  SubstractIcon,
} from "../../../components/Icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/Popover";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { pluralize } from "../../../utilities/pluralize";
import { calcServingsPresets } from "../utilities/servings";
import { HeadingS } from "../../../components/Heading";
import { cn } from "../../../utilities";

import { Toolbar } from "./Toolbar";
import { IngredientsTable } from "./IngredientsTable";

interface SidebarLeftProps {
  recipe: RecipeDocument;
  onClose(): void;
}

export function SidebarLeft({ recipe, onClose }: SidebarLeftProps) {
  const defaultServings = recipe.yield ? parseInt(recipe.yield) : 1;
  const [servings, setServings] = useState(String(defaultServings));
  const servingsOptions = calcServingsPresets(
    Number(servings) || defaultServings,
  );

  return (
    <div
      className={cn(
        "flex h-screen min-w-[300px] flex-col flex-nowrap border-r bg-background 2xl:min-w-[400px]",
        "md:absolute md:right-0",
      )}
    >
      <div className="shrink-0">
        <Toolbar
          leftControls={
            <Button onClick={onClose} variant="secondary" size="iconSm">
              <CloseLeftSidebarIcon className="h-4 w-4" />
            </Button>
          }
          rightControls={
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="secondary" size="sm" className="flex gap-2">
                  <CalcServingsIcon className="h-4 w-4" />
                  <span>
                    {servings || defaultServings}{" "}
                    {pluralize("serving", servings || defaultServings)}
                  </span>
                </Button>
              </PopoverTrigger>
              <PopoverContent align="end" className="w-auto">
                <div className="space-y-1">
                  <div className="flex items-center justify-between gap-2">
                    <HeadingS>Adjust servings</HeadingS>
                    <Button
                      variant="monochromeLink"
                      size="xs"
                      onClick={() => setServings(String(defaultServings))}
                    >
                      Reset
                    </Button>
                  </div>
                  <div>
                    <Label className="hidden">Custom servings</Label>
                    <div className="flex items-center gap-1">
                      <Button
                        size="icon"
                        variant="secondary"
                        className="text-xs"
                        disabled={Number(servings) <= 0.5}
                        onClick={() =>
                          setServings(String(servingsOptions.smaller))
                        }
                      >
                        {servingsOptions.smaller}
                      </Button>
                      <Button
                        size="icon"
                        variant="secondary"
                        className="text-xs"
                        disabled={Number(servings) <= 0.5}
                        onClick={() =>
                          setServings(String(Number(servings) - 1))
                        }
                      >
                        <SubstractIcon className="h-4 w-4" />
                      </Button>
                      <Input
                        className="w-[50px] text-center"
                        value={servings}
                        onChange={(event) => {
                          setServings(event.target.value);
                        }}
                      />
                      <Button
                        size="icon"
                        variant="secondary"
                        className="text-xs"
                        disabled={!servings}
                        onClick={() =>
                          setServings(String(Number(servings) + 1))
                        }
                      >
                        <AddIcon className="h-4 w-4" />
                      </Button>
                      <Button
                        size="icon"
                        variant="secondary"
                        className="text-xs"
                        onClick={() =>
                          setServings(String(servingsOptions.bigger))
                        }
                      >
                        {servingsOptions.bigger}
                      </Button>
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          }
        />
      </div>
      <div className="overflow-y-auto">
        <div className="space-y-1 p-4 py-6">
          <HeadingS>Ingredients</HeadingS>
          <IngredientsTable
            recipe={recipe}
            defaultServings={defaultServings}
            servings={servings ? Number(servings) : defaultServings}
          />
        </div>
      </div>
    </div>
  );
}
