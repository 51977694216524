import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  filtersToObject,
  filtersToString,
  queryToRecipeFilters,
  RecipeFilters,
  recipeFiltersToQuery,
} from "../../utilities/filter";
import { Button } from "../../components/Button";
import { HeadingM } from "../../components/Heading";
import { AddIcon, NoResultsIcon } from "../../components/Icons";
import { RecipeList } from "../../patterns/RecipeList";
import { RecipeFilter } from "../../patterns/RecipeFilter";
import { RecipeCard } from "../../patterns/RecipeCard";
import { TopBar } from "../../patterns/TopBar";
import {
  OrderControl,
  Value,
  defaultValue,
} from "../../patterns/RecipeFilter/components/OrderControl";

import { useSearchDataLoader } from "./loader";
import { AddCollectionDialog } from "./components/AddCollectionDialog";

export function Search() {
  const navigate = useNavigate();
  const { searchTerm } = useParams();

  useEffect(() => {
    if (searchTerm == undefined) {
      navigate(
        `/search/${encodeURIComponent(
          filtersToString(recipeFiltersToQuery({ sort: defaultValue })),
        )}`,
        { replace: true },
      );
    }
  }, []);

  const { recipes } = useSearchDataLoader();

  const searchFilters = searchTerm
    ? queryToRecipeFilters(filtersToObject(searchTerm))
    : undefined;

  function updateSearchURL(searchObject: RecipeFilters) {
    navigate(
      `/search/${encodeURIComponent(
        filtersToString(recipeFiltersToQuery(searchObject)),
      )}`,
    );
  }

  const handleFilterValueChange = (values: RecipeFilters) =>
    updateSearchURL({ ...searchFilters, ...values });

  const handleSortValueChange = (values: Value) =>
    updateSearchURL({ ...searchFilters, sort: values });

  return (
    <>
      <TopBar>
        <HeadingM>Search</HeadingM>
        <div className="flex gap-2">
          <AddCollectionDialog
            filter={searchTerm}
            onSuccess={(collectionId) =>
              navigate(`/collections/${collectionId}`)
            }
          >
            <Button size="sm" variant="secondary" className="flex gap-1.5">
              <AddIcon className="h-4 w-4" /> Save as collection
            </Button>
          </AddCollectionDialog>
          <OrderControl
            value={searchFilters?.sort ?? defaultValue}
            onChange={handleSortValueChange}
          />
        </div>
      </TopBar>
      <div className="flex flex-col gap-2 px-4 py-3 sm:flex-row sm:gap-4">
        <div className="sm:sticky sm:top-0 sm:w-[250px] sm:flex-[0_0_250px]">
          <RecipeFilter onValuesChange={handleFilterValueChange} />
        </div>
        <div className="flex-[1_1_auto] overflow-y-auto">
          {!recipes?.length && (
            <div className="flex h-full w-full flex-col items-center justify-center gap-4">
              <div>
                <NoResultsIcon className="h-10 w-10" />
              </div>
              <HeadingM>No recipes found</HeadingM>
            </div>
          )}
          <div className="sm:pl-0">
            <RecipeList>
              {recipes?.map((recipe) => (
                <RecipeCard key={recipe.id} recipe={recipe} />
              ))}
            </RecipeList>
          </div>
        </div>
      </div>
    </>
  );
}
