import { UserDocument } from "db";

export async function updateTextSettings(
  user: UserDocument,
  textSettings: any,
) {
  try {
    await user?.modify((user) => {
      user = { ...user, web: { textSettings } };
      return user;
    });
  } catch (err) {
    console.error("Text settings could not be updated", err);
  }
}

export async function updateNotificationSettings(
  user: UserDocument,
  enabled: boolean,
) {
  try {
    await user?.modify((user) => {
      user = { ...user, allowNotifications: enabled };
      return user;
    });
  } catch (err) {
    console.error("Notification settings could not be updated", err);
  }
}
