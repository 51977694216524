import React from "react";
import { AnnotationDocument, RecipeDocument, RecipeEventDocument } from "db";
import { useHotkeys } from "react-hotkeys-hook";
import pluralize from "pluralize";

import { Badge } from "../../../components/Badge";
import { Button, buttonVariants } from "../../../components/Button";
import { HeadingS } from "../../../components/Heading";
import { CloseRightSidebarIcon } from "../../../components/Icons";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/Tabs";
import { Source } from "../../../patterns/Source";
import { cn } from "../../../utilities";
import { dateFromNow } from "../../../utilities/dates";
import { formatDuration } from "../../../utilities/duration";
import { useUserDataLoader } from "../../../utilities/user-data";
import { MultiSelect } from "../../../components/MultiSelect-generic";
import {
  updateRecipeCuisines,
  updateRecipeDescription,
  updateRecipeMeals,
  updateRecipeTags,
  updateRecipeFoodTypes,
  updateRecipeNote,
} from "../../../actions/recipe";
import { GrowingTextarea } from "../../../patterns/GrowingTextarea";

import { MetadataTable } from "./MetadataTable";
import { Toolbar } from "./Toolbar";

import "./SidebarRight.css";

interface SidebarRightProps {
  annotations: AnnotationDocument[];
  recipe: RecipeDocument;
  recipeEvents: RecipeEventDocument[];
  onClose(): void;
}

export function SidebarRight({
  annotations,
  recipe,
  recipeEvents,
  onClose,
}: SidebarRightProps) {
  const { tags, meals, cuisines, foodTypes } = useUserDataLoader();

  const lastMade = recipeEvents[0]?.addedAt;
  const timesMade = recipeEvents.length;

  useHotkeys("o", () => {
    window.open(recipe.url, "_blank");
  });

  return (
    <div className="flex h-screen min-w-[300px] flex-col flex-nowrap border-l bg-background 2xl:min-w-[400px]">
      <div className="shrink-0">
        <Tabs defaultValue="info">
          <div className="flex h-screen flex-col flex-nowrap">
            <div className="shrink-0">
              <Toolbar
                leftControls={
                  <TabsList
                    className={cn(
                      buttonVariants({ variant: "secondary" }),
                      "h-8 rounded-lg p-1",
                    )}
                  >
                    <TabsTrigger value="info" className="py-0.5">
                      Info
                    </TabsTrigger>
                    <TabsTrigger value="notes" className="py-0.5">
                      Notes
                    </TabsTrigger>
                    <TabsTrigger value="links" className="py-0.5">
                      Links
                    </TabsTrigger>
                  </TabsList>
                }
                rightControls={
                  <Button onClick={onClose} variant="secondary" size="iconSm">
                    <CloseRightSidebarIcon className="h-4 w-4" />
                  </Button>
                }
              />
            </div>
            <div className="flex-initial overflow-y-auto p-4 py-6 text-sm">
              <TabsContent value="info">
                <div className="space-y-6">
                  <div className="space-y-1">
                    <HeadingS>Source</HeadingS>
                    <div>
                      {recipe.url && (
                        <a href={recipe.url}>
                          <Source
                            icon={recipe.website?.icons?.[0]}
                            url={recipe.url}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="group space-y-2">
                    <HeadingS>Description</HeadingS>
                    <GrowingTextarea
                      defaultValue={recipe.description}
                      onInput={(
                        event: React.ChangeEvent<HTMLTextAreaElement>,
                      ) => {
                        const eventTarget = event.target;
                        updateRecipeDescription(recipe, eventTarget.value);
                      }}
                      placeholder={
                        recipe.description ? undefined : "Add a description..."
                      }
                      variant="inPlaceEdit"
                    />
                  </div>
                  <div className="space-y-1">
                    <HeadingS>Metadata</HeadingS>
                    <MetadataTable
                      items={[
                        {
                          label: "Total time",
                          value: formatDuration(recipe.totalTime),
                        },
                        {
                          label: "Preparation",
                          value: formatDuration(recipe.preparationTime),
                        },
                        {
                          label: "Cuisine",
                          value: (
                            <MultiSelect
                              options={cuisines}
                              label="Cuisine"
                              value={recipe.cuisines ?? []}
                              renderItem={(item) => (
                                <Badge key={item} variant="outline">
                                  {item}
                                </Badge>
                              )}
                              onSelect={(value) => {
                                updateRecipeCuisines(recipe, value);
                              }}
                            />
                          ),
                        },
                        {
                          label: "Food type",
                          value: (
                            <MultiSelect
                              options={foodTypes}
                              label="Food type"
                              value={recipe.foodTypes ?? []}
                              renderItem={(item) => (
                                <Badge key={item} variant="outline">
                                  {item}
                                </Badge>
                              )}
                              onSelect={(value) => {
                                updateRecipeFoodTypes(recipe, value);
                              }}
                            />
                          ),
                        },
                        {
                          label: "Tag",
                          value: (
                            <MultiSelect
                              options={tags}
                              label="Tag"
                              value={recipe.tags ?? []}
                              renderItem={(item) => (
                                <Badge key={item} variant="outline">
                                  {item}
                                </Badge>
                              )}
                              onSelect={(value) => {
                                updateRecipeTags(recipe, value);
                              }}
                            />
                          ),
                        },
                        {
                          label: "Meal",
                          value: (
                            <MultiSelect
                              options={meals}
                              label="Meal"
                              value={recipe.meals ?? []}
                              renderItem={(item) => (
                                <Badge key={item} variant="outline">
                                  {item}
                                </Badge>
                              )}
                              onSelect={(value) => {
                                updateRecipeMeals(recipe, value);
                              }}
                            />
                          ),
                        },
                        {
                          label: "Servings",
                          value: recipe.yield,
                        },
                      ]}
                    />
                  </div>
                  <div className="space-y-1">
                    <HeadingS>Stats</HeadingS>
                    <MetadataTable
                      items={[
                        {
                          label: "Last made",
                          value: lastMade ? (
                            dateFromNow(lastMade)
                          ) : (
                            <span className="italic text-muted-foreground">
                              Never
                            </span>
                          ),
                        },
                        {
                          label: "Made",
                          value: timesMade ? (
                            pluralize("times", timesMade, true)
                          ) : (
                            <span className="italic text-muted-foreground">
                              Never
                            </span>
                          ),
                        },
                        { label: "Added", value: dateFromNow(recipe.addedAt) },
                      ]}
                    />
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="notes">
                <div className="space-y-6">
                  <div className="space-y-1">
                    <HeadingS>Recipe Notes</HeadingS>
                    <GrowingTextarea
                      defaultValue={recipe.note}
                      placeholder="Add a recipe note..."
                      variant="inPlaceEdit"
                      onInput={(
                        event: React.ChangeEvent<HTMLTextAreaElement>,
                      ) => {
                        const eventTarget = event.target;
                        updateRecipeNote(recipe, eventTarget.value);
                      }}
                    />
                  </div>
                  <section className="space-y-2">
                    <HeadingS>Highlights</HeadingS>
                    {annotations.length > 0 ? (
                      <ul className="space-y-5">
                        {annotations.sort(sortRanges).map((annotation) => (
                          <li key={annotation.range} className="space-y-1.5">
                            <div>
                              <span className="bg-yellow-50 underline decoration-yellow-400 underline-offset-2 dark:bg-yellow-500/20 dark:decoration-yellow-600/80">
                                {annotation.content}
                              </span>
                            </div>
                            <blockquote className="ml-1 border-l-2 pl-3 text-sm italic">
                              {annotation.note}
                            </blockquote>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="italic text-muted-foreground">
                        No highlights yet. Select text in the recipe
                        instructions to add highlights and notes.
                      </div>
                    )}
                  </section>
                </div>
              </TabsContent>
              <TabsContent value="links">
                <HeadingS>Links</HeadingS>
              </TabsContent>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

function sortRanges(a: AnnotationDocument, b: AnnotationDocument) {
  const rangeA = parseInt(a.range?.split("$")[0] ?? "0");
  const rangeB = parseInt(b.range?.split("$")[0] ?? "0");

  if (rangeA < rangeB) {
    return -1;
  }
  if (rangeA > rangeB) {
    return 1;
  }
  return 0;
}
