import * as React from "react";

import { cn } from "../utilities";

export function Skeleton({
  noAnimation,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { noAnimation?: boolean }) {
  return (
    <div
      className={cn(
        "animate-pulse rounded-md bg-primary/10",
        noAnimation && "animate-none",
        className,
      )}
      {...props}
    />
  );
}
