import React, { useState } from "react";
import { type Ingredient } from "db";

import { TableCell, TableRow } from "../../../../components/Table";
import { cn } from "../../../../utilities";
import { pluralize } from "../../../../utilities/pluralize";
import { ContentEditable } from "../../../../components/ContentEditable";

interface IngredientsTableRowProps {
  ingredient: Ingredient;
  servings: number;
  defaultServings: number;
  onUpdateValue(value: Ingredient): void;
}

export function IngredientsTableRow({
  ingredient,
  servings,
  defaultServings,
  onUpdateValue,
}: IngredientsTableRowProps) {
  const isContentEditable = servings === defaultServings;
  const [editable, setEditable] = useState(false);

  const calcQuantity = (quantity: string | undefined) => {
    if (servings !== defaultServings && quantity) {
      const updatedQuantity = (Number(quantity) / defaultServings) * servings;
      return Math.round(updatedQuantity * 100) / 100;
    } else {
      return quantity;
    }
  };

  const amount = ingredient.amount?.[0];
  if (amount == null) {
    return;
  }

  return (
    <TableRow
      onFocus={() => setEditable(true)}
      onBlur={() => setEditable(false)}
    >
      <TableCell className="flex justify-end gap-1 p-2 py-1 pr-1 text-right align-top font-medium">
        <ContentEditable
          contentEditable={isContentEditable}
          placeholder="1"
          value={calcQuantity(amount.quantity?.toString())?.toString()}
          singleLine
          onInput={(value) => {
            onUpdateValue({
              ...ingredient,
              amount: [
                { ...amount, quantity: parseInt(value) },
                ...(ingredient.amount?.slice(1) || []),
              ],
            });
          }}
          textStyle="whitespace-nowrap"
          className={cn(
            !ingredient.amount?.[0].quantity && "w-0 overflow-hidden",
            editable && "w-auto",
          )}
        />
        <ContentEditable
          contentEditable={isContentEditable}
          singleLine
          value={
            amount.unit &&
            pluralize(
              amount.unit,
              servings !== defaultServings && amount.quantity
                ? (Number(amount.quantity) / defaultServings) * servings
                : (amount.quantity ?? 1),
            )
          }
          placeholder="unit"
          onInput={(value) => {
            onUpdateValue({
              ...ingredient,
              amount: [
                { ...amount, unit: value },
                ...(ingredient.amount?.slice(1) || []),
              ],
            });
          }}
          className={cn(
            !amount.unit && "w-0 overflow-hidden",
            editable && "w-auto",
          )}
        />
      </TableCell>
      <TableCell className="py-1 align-top">
        <div className="flex flex-col items-start justify-start">
          <ContentEditable
            contentEditable={isContentEditable}
            value={ingredient.name}
            placeholder="Ingredient"
            onInput={(value) => {
              onUpdateValue({
                ...ingredient,
                name: value,
              });
            }}
          />
          <ContentEditable
            contentEditable={isContentEditable}
            value={ingredient.comment}
            onInput={(value) => {
              onUpdateValue({
                ...ingredient,
                comment: value,
              });
            }}
            placeholder="other, e.g. diced"
            className={cn(
              "h-0 overflow-hidden font-normal text-muted-foreground focus:h-auto",
              (editable || ingredient.comment) && "h-auto",
            )}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}
