import * as React from "react";
import { NavLink } from "react-router-dom";

import { cn } from "./utilities";
import { Avatar, AvatarImage, AvatarFallback } from "./components/Avatar";
import {
  CollectionsIcon,
  SearchIcon,
  AddIcon,
  LibraryIcon,
  UserIcon,
} from "./components/Icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./components/Tooltip";
import { useUserDataLoader } from "./utilities/user-data";
import { Button } from "./components/Button";
import { DropdownMenuShortcut } from "./components/DropdownMenu";
import { useCommandPalette } from "./CommandPaletteProvider";
import { getEmoji } from "./utilities/emoji";

const mainNavItems = [
  {
    icon: <LibraryIcon className="h-4 w-4" />,
    title: "Library",
    path: "/library",
  },
  {
    icon: <SearchIcon className="h-4 w-4" />,
    title: "Search",
    path: "/search",
    exactPath: false,
  },
  {
    icon: <CollectionsIcon className="h-4 w-4" />,
    title: "Collections",
    path: "/collections",
  },
];

export function Navigation() {
  const { collections, user } = useUserDataLoader();
  const { openPalettePage } = useCommandPalette();

  return (
    <div className="flex h-full flex-col items-center justify-between border-r pb-3 dark:bg-background">
      <div className="flex flex-col items-center">
        <div className="h-[52px] border-b pt-3.5 text-center text-xs font-bold leading-none">
          <NavLink
            to="/"
            className="bg-gradient-to-bl from-[#f0a800] to-[#dd1e84] bg-clip-text hover:text-transparent aria-[current=page]:text-transparent"
          >
            <span>
              NOM
              <br />
              NOM
            </span>
          </NavLink>
        </div>
        <nav className="border-b pb-3 pt-3">
          <ul className="space-y-1">
            {mainNavItems.map(({ title, exactPath, path, icon }) => (
              <li key={title}>
                <NavLinkWithTooltip
                  title={title}
                  path={path}
                  icon={icon}
                  exactPath={exactPath}
                />
              </li>
            ))}
          </ul>
        </nav>
        <nav className="pt-3">
          <ul className="space-y-1">
            {collections.map((collection) => (
              <li key={collection.id}>
                <NavLinkWithTooltip
                  // TODO: Should we make collection.title required?
                  title={collection.title ?? "Collection"}
                  path={`collections/${collection.id}`}
                  exactPath={false}
                />
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="space-y-3">
        <div>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="iconSm"
                variant="default"
                className="rounded-lg"
                onClick={() => {
                  openPalettePage(["add-recipe"]);
                }}
              >
                <AddIcon className="h-4 w-4 stroke-[3px]" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">
              {/* Need to change this to not be the DROPDOWN shortcut */}
              Add recipe <DropdownMenuShortcut>A</DropdownMenuShortcut>
            </TooltipContent>
          </Tooltip>
        </div>
        <nav className="border-t pt-3">
          <Tooltip>
            <TooltipTrigger asChild>
              <NavLink to="/account">
                <Avatar className="h-8 w-8">
                  <AvatarImage
                    src={user?.profileImageUrl}
                    alt={`${user?.firstName} ${user?.lastName}`}
                  />
                  <AvatarFallback>
                    {user?.firstName && user?.lastName ? (
                      `${user?.firstName?.[0]}${user?.lastName?.[0]}`.toUpperCase()
                    ) : (
                      <UserIcon className="h-5 w-5" />
                    )}
                  </AvatarFallback>
                </Avatar>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent side="right">Account</TooltipContent>
          </Tooltip>
        </nav>
      </div>
    </div>
  );
}

function NavLinkWithTooltip({
  path,
  icon,
  title,
  exactPath = true,
}: {
  path: string;
  icon?: React.ReactNode;
  title: string;
  exactPath?: boolean;
}) {
  const emoji = getEmoji(title);

  return (
    <Tooltip>
      {/* should be using asChild but doesn't work with NavLink */}
      {/* https://github.com/radix-ui/primitives/issues/1158 */}
      <TooltipTrigger>
        <NavLink
          to={path}
          end={exactPath}
          className={({ isActive }) =>
            cn(
              "flex h-8 w-8 flex-col items-center justify-center rounded-lg bg-secondary bg-gradient-to-bl from-background to-muted p-1.5 text-lg text-secondary-foreground dark:bg-gradient-to-tr",
              !isActive &&
                "hover:bg-background hover:bg-gradient-to-bl hover:from-muted",
              isActive &&
                "bg-background bg-gradient-to-bl from-[#f0a800] to-[#dd1e84] text-white hover:text-white",
            )
          }
        >
          {icon ? (
            icon
          ) : (
            <div className="text-xs font-medium uppercase leading-none">
              {emoji ? emoji : title?.slice(0, 2)}
            </div>
          )}
        </NavLink>
      </TooltipTrigger>
      <TooltipContent side="right">{title}</TooltipContent>
    </Tooltip>
  );
}
