import * as React from "react";
import { FormEvent } from "react";

import { addRecipe } from "../../actions/recipe";
import { useCommandPalette } from "../../CommandPaletteProvider";
import { Button } from "../../components/Button";
import { HeadingL, HeadingXL } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Page } from "../../components/Page";
import { NoRecipeCard, RecipeCard } from "../../patterns/RecipeCard";
import { RecipeList } from "../../patterns/RecipeList";
import { useUserDataLoader } from "../../utilities/user-data";
import { AlertExpiringTrial } from "../../patterns/Subscription/AlertExpiringTrial";

import { useHomeDocumentsDataLoader } from "./loader";

export function Home() {
  const { user } = useUserDataLoader();
  const { recentlyAddedRecipes: recipes, recentlyCookedRecipes } =
    useHomeDocumentsDataLoader();
  const { openPalettePage } = useCommandPalette();

  async function createRecipe(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = event.currentTarget;
    const data = new FormData(form);
    const url = data.get("url")?.toString();
    if (url) {
      addRecipe(url, () => form.reset());
    }
  }

  const greeting = user?.firstName
    ? `Welcome, ${user?.firstName}!`
    : "Welcome!";

  return (
    <Page>
      <AlertExpiringTrial />
      <HeadingXL>{greeting}</HeadingXL>
      <div className="flex flex-wrap items-center justify-between gap-2 rounded-lg bg-gradient-to-r from-[#f0a800] to-[#dd1e84] px-4 py-4 dark:bg-gradient-to-l sm:gap-4 sm:py-5 md:gap-20">
        <HeadingL className="text-background">
          Discovered a new recipe?
        </HeadingL>
        <form onSubmit={createRecipe} className="flex grow gap-2">
          <Input placeholder="Add recipe URL..." name="url" />
          <Button variant="default">Add recipe</Button>
        </form>
      </div>
      <div className="space-y-2">
        <HeadingL>Recently added</HeadingL>
        <RecipeList singleRow>
          {recipes?.length ? (
            recipes.map((recipe) => (
              <RecipeCard key={`recently-added-${recipe.id}`} recipe={recipe} />
            ))
          ) : (
            <>
              <NoRecipeCard onClick={() => openPalettePage(["add-recipe"])} />
              <NoRecipeCard className="opacity-50" />
              <NoRecipeCard className="opacity-30" />
              <NoRecipeCard className="opacity-10" />
            </>
          )}
        </RecipeList>
      </div>
      <div className="space-y-2">
        <HeadingL>Recently cooked</HeadingL>
        <RecipeList singleRow>
          {recentlyCookedRecipes?.length ? (
            recentlyCookedRecipes?.map((recipe) => (
              <RecipeCard key={`recently-added-${recipe.id}`} recipe={recipe} />
            ))
          ) : (
            <>
              <NoRecipeCard onClick={() => openPalettePage(["add-recipe"])} />
              <NoRecipeCard className="opacity-50" />
              <NoRecipeCard className="opacity-30" />
              <NoRecipeCard className="opacity-10" />
            </>
          )}
        </RecipeList>
      </div>
    </Page>
  );
}
