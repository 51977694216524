import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { ViewDocument, RxQuery, RecipeDocument } from "db";

import { getDb } from "../../db";
import { filtersToObject } from "../../utilities/filter";

export type CollectionsWithTotalCount = {
  data: ViewDocument;
  totalCount: number;
};

export const loader = async function () {
  const db = await getDb();

  const collectionsQuery = db.views.find();

  const [collections] = await Promise.all([collectionsQuery.exec()]);

  return {
    collections,
    collectionsQuery,
  };
};

export const useCollectionsDocumentDataLoader =
  function (): CollectionsWithTotalCount[] {
    const { collections: initialCollections, collectionsQuery } =
      useLoaderData() as {
        collections: ViewDocument[];
        collectionsQuery: RxQuery<ViewDocument>;
      };

    const [collections, setCollections] =
      useState<ViewDocument[]>(initialCollections);
    const [collectionsRecipes, setCollectionsRecipes] = useState<
      Map<ViewDocument, RecipeDocument[]>
    >(new Map());

    useEffect(() => {
      const subscription = collectionsQuery.$.subscribe((collections) =>
        setCollections(collections as ViewDocument[]),
      );

      return () => {
        subscription.unsubscribe();
      };
    }, [initialCollections]);

    const db = collectionsQuery.collection.database;

    useEffect(() => {
      setCollectionsRecipes(new Map());

      const subscriptions = collections.map((collection: ViewDocument) => {
        const recipesQuery = db.recipes.find(
          filtersToObject(collection.filter ?? ""),
        );
        const subscription = recipesQuery.$.subscribe((recipes) => {
          setCollectionsRecipes((previousValue) => {
            const newCollectionRecipes = new Map(previousValue);
            newCollectionRecipes.set(collection, recipes);
            return newCollectionRecipes;
          });
        });
        recipesQuery.exec();
        return subscription;
      });

      return () => {
        subscriptions.forEach((subscription) => subscription.unsubscribe());
      };
    }, [collections]);

    return collections.map((collection) => ({
      data: collection,
      totalCount: collectionsRecipes.get(collection)?.length || 0,
    }));
  };
