import { DatabaseConfig } from "db/types";

interface Config extends DatabaseConfig {
  url: {
    base: string;
    billing: string;
    checkout: string;
    userDelete: string;
    userSignIn: string;
    userSignOut: string;
  };
}

export const config: Config = {
  mode: import.meta.env.MODE as "development" | "production",
  api: {
    http: import.meta.env.VITE_API_HTTP_URL,
    ws: import.meta.env.VITE_API_WS_URL,
  },
  url: {
    base: import.meta.env.VITE_BASE_URL,
    billing: import.meta.env.VITE_BILLING_URL,
    checkout: import.meta.env.VITE_CHECKOUT_URL,
    userDelete: import.meta.env.VITE_USER_DELETE_URL,
    userSignIn: import.meta.env.VITE_USER_SIGN_IN_URL,
    userSignOut: import.meta.env.VITE_USER_SIGN_OUT_URL,
  },
  env: "web",
};
