import { createDatabase, Database } from "db";
import { useEffect, useState } from "react";
import { getRxStorageSharedWorker } from "rxdb-premium/plugins/storage-worker";

import { config } from "./config";

export async function getDb() {
  const storage = getRxStorageSharedWorker({
    workerInput: "/indexeddb.worker.js",
    workerOptions: {
      name: "nomnom",
      type: "module",
      credentials: "omit",
    },
  });
  return createDatabase(storage, config);
}

export function useDb() {
  const [db, setDb] = useState<Database>();
  useEffect(() => {
    (async () => setDb(await getDb()))();
  }, []);

  return db;
}
