import * as React from "react";
import { useNavigate } from "react-router-dom";

import { HeadingM } from "../../components/Heading";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Table";
import { Link } from "../../components/Link";
import { Button } from "../../components/Button";
import { ActionsIcon, AddIcon } from "../../components/Icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/DropdownMenu";
import {
  filtersToObject,
  filtersToString,
  humanizeRecipeFilters,
  queryToRecipeFilters,
} from "../../utilities/filter";
import { Badge } from "../../components/Badge";
import { deleteCollection } from "../../actions/collections";
import { TopBar } from "../../patterns/TopBar";
import { AddCollectionDialog } from "../Search/components/AddCollectionDialog";

import { useCollectionsDocumentDataLoader } from "./loader";

export function Collections() {
  const collections = useCollectionsDocumentDataLoader();
  const navigate = useNavigate();

  return (
    <>
      <TopBar>
        <HeadingM>Collections</HeadingM>
        <AddCollectionDialog
          filter={filtersToString({ sort: [{ addedAt: "desc" }] })}
          onSuccess={(collectionId) =>
            navigate(`/collections/${collectionId}/edit`)
          }
        >
          <Button
            size="sm"
            variant="secondary"
            className="flex items-center gap-1.5"
          >
            <AddIcon className="h-4 w-4" /> Collection
          </Button>
        </AddCollectionDialog>
      </TopBar>
      <Table>
        <TableCaption>A list of your recipe collections.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Name</TableHead>
            <TableHead>Filter</TableHead>
            <TableHead className="text-right"># of recipes</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {collections.map((collection) => (
            <TableRow key={collection.data.title}>
              <TableCell className="font-semibold">
                <Link to={`/collections/${collection.data.id}`}>
                  {collection.data.title}
                </Link>
              </TableCell>
              <TableCell>
                <div className="flex flex-wrap gap-1">
                  {collection.data.filter && (
                    <>
                      {humanizeRecipeFilters(
                        queryToRecipeFilters(
                          filtersToObject(collection.data.filter),
                        ),
                      ).map((value) => (
                        <Badge
                          key={`${collection.data.title}-${value}`}
                          variant="outline"
                        >
                          {value}
                        </Badge>
                      ))}
                    </>
                  )}
                </div>
              </TableCell>
              <TableCell className="text-right">
                {collection.totalCount}
              </TableCell>
              <TableCell className="text-right">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="secondary" size="iconSm">
                      <ActionsIcon className="h-5 w-5" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-40" align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onSelect={() => {
                          navigate(`/collections/${collection.data.id}/edit`);
                        }}
                      >
                        <span>Edit</span>
                        {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        className="text-destructive focus:text-destructive"
                        onSelect={() => deleteCollection(collection.data)}
                      >
                        <span>Delete</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
