import * as React from "react";
import { ReactNode } from "react";

import { cn } from "../../../utilities";

export function Toolbar({
  leftControls,
  rightControls,
}: {
  leftControls?: ReactNode;
  rightControls?: ReactNode;
}) {
  return (
    <div
      className={cn("flex h-[52px] items-center justify-between border-b px-4")}
    >
      <div>{leftControls}</div>
      <div>{rightControls}</div>
    </div>
  );
}
