import React from "react";
import { produce } from "immer";
import { RecipeDocument } from "db";

import { Table, TableBody } from "../../../../components/Table";
import { updateRecipeIngredients } from "../../../../actions/recipe";

import { IngredientsTableRow } from "./IngredientsTableRow";

interface Props {
  recipe: RecipeDocument;
  defaultServings: number;
  servings: number;
}

export function IngredientsTable({ recipe, defaultServings, servings }: Props) {
  const { ingredientGroups } = recipe;

  return (
    <>
      {ingredientGroups?.map(({ purpose, ingredients }, groupIndex) => {
        return (
          <div key={`ingredient-group-${purpose}-${groupIndex}`}>
            {purpose && (
              <h1 className="border-b pb-2 text-sm text-muted-foreground">
                {purpose}
              </h1>
            )}
            <Table className="mb-4 text-sm font-medium">
              <TableBody>
                {ingredients?.map((ingredient, ingredientIndex) => (
                  <IngredientsTableRow
                    ingredient={ingredient}
                    defaultServings={defaultServings}
                    servings={servings}
                    key={`ingredient-group-${groupIndex}-i-${ingredientIndex}`}
                    onUpdateValue={async (value: Record<string, any>) => {
                      const newIngredients = produce(
                        ingredientGroups,
                        (draft) => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-expect-error
                          draft[groupIndex].ingredients![ingredientIndex] =
                            value;
                        },
                      );
                      await updateRecipeIngredients(recipe, newIngredients);
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        );
      }) ?? null}
    </>
  );
}
