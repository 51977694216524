import { useMediaQueries } from "@react-hook/media-query";

export function useBreakpoints() {
  const { matches } = useMediaQueries<{
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    is2xl: boolean;
  }>({
    isXs: "(min-width: 0px)",
    isSm: "(min-width: 640px)",
    isMd: "(min-width: 768px)",
    isLg: "(min-width: 1024px)",
    isXl: "(min-width: 1280px)",
    is2xl: "(min-width: 1536px)",
  });

  const values = {
    atLeast: matches,
    activeBreakpoint: Object.entries(matches)
      .reverse()
      .find(([_key, value]: [string, boolean]) => value === true)?.[0],
  };

  return values;
}
