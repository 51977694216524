import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxCollection,
  RxDocument,
  RxCollectionCreator,
} from "rxdb";

import { toGid } from "../gid";
import { CollectionConfig } from "../types";

const jsonSchema = {
  title: "Recipe event schema",
  description: "A schema describing a recipe event",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    id: {
      type: "string",
      maxLength: 36,
      final: true,
    },
    category: {
      type: "string",
      enum: ["COOKED"],
    },
    addedAt: {
      type: "string",
    },
    recipeId: {
      type: "string",
      maxLength: 36,
    },
  },
  required: ["id", "recipeId", "category", "addedAt"],
  indexes: ["recipeId"],
} as const;
const typedSchema = toTypedRxJsonSchema(jsonSchema);

type RecipeEventType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof typedSchema
>;

export type RecipeEventDocument = RxDocument<RecipeEventType>;
export type RecipeEventCollection = RxCollection<RecipeEventType>;

const schema: RxJsonSchema<RecipeEventType> = jsonSchema;

async function setup(collection: RecipeEventCollection) {
  const db = collection.database;

  // middleware
  collection.preInsert(async function (data) {
    data.id = toGid("nom-nom", "RecipeEvent", data.id);
    data.addedAt = new Date(Date.now()).toISOString();
  }, false);

  collection.postInsert(async function (data) {
    const recipe = await db.collections.recipes.findOne(data.recipeId).exec();
    await recipe?.patch({ lastCookedAt: data.addedAt });
  }, false);

  collection.postRemove(async function (data) {
    const recipe = await db.collections.recipes.findOne(data.recipeId).exec();

    if (recipe.lastCookedAt === data.addedAt) {
      const newestCooked = await collection
        .findOne({
          selector: { recipeId: recipe.id },
          sort: [{ addedAt: "desc" }],
        })
        .exec();

      await recipe?.patch({ lastCookedAt: newestCooked?.addedAt ?? undefined });
    }
    // TODO:
  }, false);
}

export const RecipeEvent: RxCollectionCreator<RecipeEventType> & {
  config: CollectionConfig<RecipeEventType>;
} = {
  config: {
    replicate: true,
    setup,
  },
  schema,
};
