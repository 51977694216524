import * as React from "react";

import { cn } from "../utilities";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./Command";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { SelectedIcon, TagIcon } from "./Icons";
import { Badge } from "./Badge";
import { Button } from "./Button";

export function MultiSelect({
  options,
  label,
  value = [],
  onSelect,
  renderItem,
}: {
  options: string[];
  label: string;
  value: string[] | undefined;
  onSelect(values: string[]): void;
  renderItem(value: string): React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {/* <PopoverTrigger asChild>
        <Button
          role="combobox"
          aria-expanded={open}
          variant="ghost"
          className="h-auto p-0"
        > */}
      <div className="flex items-center justify-between gap-2">
        {value.length > 0 ? (
          <div className="flex flex-wrap gap-1">{value.map(renderItem)}</div>
        ) : (
          <div className="text-muted-foreground/40">None yet</div>
        )}
        <PopoverTrigger asChild>
          <Button
            variant="monochromeLink"
            aria-label={label}
            size="linkXs"
            className="flex gap-1"
          >
            Edit
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent
        className="max-h-[var(--radix-popover-content-available-height)] w-[200px] p-0"
        align="end"
        side="bottom"
      >
        <Command
          loop
          className="max-h-[var(--radix-popover-content-available-height)]"
        >
          <CommandInput
            value={searchValue}
            onValueChange={setSearchValue}
            placeholder="Search tags..."
          />
          <CommandList>
            <CommandEmpty>
              <>
                No options found. <br />
                Add{" "}
                <Badge onClick={() => onSelect([...value, searchValue])}>
                  <TagIcon className="h-4 w-4" /> {searchValue}
                </Badge>
              </>
            </CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option}
                  onSelect={(_value) => {
                    // Note: cmdk currently lowercases values in forms for some reason, so don't use
                    // 'value' directly from the onSelect here
                    const currentValue = option;
                    if (value.includes(currentValue)) {
                      const updatedValue = [...value];
                      const currentValuePosition =
                        updatedValue.indexOf(currentValue);

                      updatedValue.splice(currentValuePosition, 1);
                      onSelect(updatedValue);
                    } else {
                      onSelect([...value, currentValue]);
                    }
                  }}
                >
                  <SelectedIcon
                    className={cn(
                      "mr-2 h-4 w-4",
                      value.includes(option) ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {option}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
