import * as RadioGroup from "@radix-ui/react-radio-group";
import React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import {
  NotificationsOffIcon,
  NotificationsOnIcon,
} from "../../../components/Icons";

interface NotificationSettings {
  enabled: boolean;
  onSettingChange(enabled: boolean): void;
}

export function NotificationSettings({
  enabled,
  onSettingChange,
}: NotificationSettings) {
  return (
    <Card>
      <CardHeader className="pb-3">
        <CardTitle>Notifications</CardTitle>
        <CardDescription>
          Choose what you want to be notified about.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form>
          <RadioGroup.Root
            defaultValue={enabled ? "enabled" : "disabled"}
            aria-label="Notification settings"
            onValueChange={(value) => {
              onSettingChange(value === "enabled" ? true : false);
            }}
          >
            <div className="grid gap-1">
              <RadioGroup.Item
                value="enabled"
                id="notifications-enabled"
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 text-left transition-all data-[state=checked]:bg-accent data-[state=checked]:text-accent-foreground"
              >
                <NotificationsOnIcon className="mt-px h-5 w-5 stroke-[1.5]" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Enabled</p>
                  <p className="text-sm text-muted-foreground">
                    Email digest, mentions & all activity.
                  </p>
                </div>
              </RadioGroup.Item>
              <RadioGroup.Item
                value="disabled"
                id="notifications-disabled"
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 text-left transition-all hover:bg-accent hover:text-accent-foreground data-[state=checked]:bg-accent data-[state=checked]:text-accent-foreground"
              >
                <NotificationsOffIcon className="mt-px h-5 w-5 stroke-[1.5]" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Disabled</p>
                  <p className="text-sm text-muted-foreground">
                    Turn off all notifications.
                  </p>
                </div>
              </RadioGroup.Item>
            </div>
          </RadioGroup.Root>
        </form>
      </CardContent>
    </Card>
  );
}
