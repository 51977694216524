import * as React from "react";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";

import { cn } from "../utilities";

export function Link({
  ghost,
  ...props
}: ReactRouterLinkProps & { ghost?: boolean }) {
  return (
    <ReactRouterLink
      {...props}
      className={cn(!ghost && "text-primary underline underline-offset-4")}
    />
  );
}
