import React from "react";

import { cn } from "../../utilities";
import { Textarea, TextareaProps } from "../../components/Textarea";

import "./GrowingTextarea.css";

export type GrowingTextareaProps = TextareaProps;

const GrowingTextarea = React.forwardRef<
  HTMLTextAreaElement,
  GrowingTextareaProps
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn("grow-wrap", className)}
      data-replicated-value={props.value ?? props.defaultValue}
    >
      <Textarea
        ref={ref}
        rows={1}
        spellCheck="false"
        // Might be needed for uncontrolled textareas
        onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          const eventTarget = event.target;
          const parentElement = eventTarget.parentElement as HTMLDivElement;
          parentElement.dataset.replicatedValue = event.target.value;
        }}
        {...props}
      />
    </div>
  );
});

GrowingTextarea.displayName = "GrowingTextarea";

export { GrowingTextarea };
