import { addDaysToDate, daysDifference } from "../../utilities/dates";
import { useUserDataLoader } from "../../utilities/user-data";

// A user is either on a trial or has a plan

const TRIAL_PERIOD = 30;

export function useSubscription() {
  const { user } = useUserDataLoader();

  const { billingPlans, billingSubscription, createdAt } = user;

  const trialDaysElapsed = daysDifference(createdAt);
  const trialDaysLeft = TRIAL_PERIOD - trialDaysElapsed;
  const trialEndsDate = addDaysToDate(TRIAL_PERIOD, createdAt);

  return {
    currentSubscription: billingSubscription,
    plans: billingPlans,
    isTrial: !billingSubscription,
    isTrialExpired: trialDaysLeft < 0,
    trialDaysElapsed,
    trialDaysLeft,
    trialEndsDate,
    isCancelled: !!billingSubscription?.cancelAt,
  };
}
