import { useEffect, useState } from "react";

export function useScrollDirection(
  ref: React.MutableRefObject<HTMLElement | null>,
) {
  const [scrollDir, setScrollDir] = useState<"UP" | "DOWN">();

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = ref.current?.scrollTop ?? 0;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = ref.current?.scrollTop ?? 0;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "DOWN" : "UP");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    ref.current?.addEventListener("scroll", onScroll);

    return () => ref.current?.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return scrollDir;
}

export function useScrolledToTop(
  ref: React.MutableRefObject<HTMLElement | null>,
) {
  const threshold = 20;
  const [scrolledToTop, setScrolledToTop] = useState(
    (ref.current?.scrollTop ?? 0) <= threshold,
  );

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      if ((ref.current?.scrollTop ?? 0) > threshold) {
        setScrolledToTop(false);
        ticking = false;
      } else {
        setScrolledToTop(true);
        ticking = false;
      }
    };

    const onScroll = () => {
      if (!ticking) {
        ticking = true;
        window.requestAnimationFrame(updateScrollDir);
      }
    };

    ref.current?.addEventListener("scroll", onScroll);

    return () => ref.current?.removeEventListener("scroll", onScroll);
  }, [scrolledToTop]);

  return scrolledToTop;
}
