import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../components/Table";

interface MetadataTableProps {
  items: { label: string; value: string | React.ReactNode }[];
}

export function MetadataTable({ items }: MetadataTableProps) {
  return (
    <Table>
      <TableBody>
        {items.map((item) => (
          <TableRow key={`metadata-${item.label}`}>
            <TableCell className="whitespace-nowrap py-1.5 pl-0 pr-2 align-top text-muted-foreground">
              {item.label}
            </TableCell>
            <TableCell className="py-1.5 pl-2 pr-0 align-top">
              {item.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
