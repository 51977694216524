import React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/Card";
import { config } from "../../config";
import { HeadingM } from "../../components/Heading";
import { Badge } from "../../components/Badge";

import { useSubscription } from "./hook";

export function Subscription() {
  const {
    isCancelled,
    isTrial,
    isTrialExpired,
    trialDaysLeft,
    trialEndsDate,
    currentSubscription,
    plans,
  } = useSubscription();

  const hasFreePlan = currentSubscription?.plan?.name === "Free";
  const planName = isTrial ? "Trial" : currentSubscription?.plan?.name;

  const descriptionText = {
    trial: isTrialExpired ? (
      <>
        Your trial expired on{" "}
        <span className="font-semibold">{trialEndsDate}</span>. To continue
        using nomnoms, please choose a plan.
      </>
    ) : (
      <>
        Your trial will expire on{" "}
        <span className="font-semibold">{trialEndsDate}</span>. To continue
        using nomnoms after the trial period ends, please choose a plan.
      </>
    ),
    plan: (
      <>
        You are currently on the{" "}
        <span className="font-semibold">{planName} plan</span> until{" "}
        <span className="font-semibold">
          {currentSubscription?.currentPeriodEnd}
        </span>
        .{" "}
        {isCancelled ? (
          <>Your subscription will end on {currentSubscription?.cancelAt}.</>
        ) : (
          <>Your subscription will renew automatically.</>
        )}
      </>
    ),
  };

  return (
    <Card>
      <CardHeader className="pb-3">
        <CardTitle>
          <div className="flex items-center gap-3">
            Subscription{" "}
            <Badge className="border-none bg-gradient-to-l from-[#f0a800] to-[#dd1e84] font-bold uppercase tracking-wide text-white">
              {planName}
            </Badge>
          </div>
        </CardTitle>
        <CardDescription>
          {descriptionText[isTrial ? "trial" : "plan"]}
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-1">
        {isTrial && (
          <button
            disabled
            type="submit"
            className="-mx-2 flex flex-row items-center justify-between gap-2 rounded-md bg-accent px-3 py-2 text-accent-foreground transition"
          >
            <div className="">
              <HeadingM className="flex items-center gap-2">
                Trial
                <div className="rounded-sm bg-destructive/70 px-1 py-0.5 text-xs font-bold text-foreground">
                  {isTrialExpired ? "Expired" : `${trialDaysLeft} days left`}
                </div>
              </HeadingM>
              <p className="text-left text-sm text-muted-foreground">
                Explore nomnoms for free during your first 30 days. Test all
                features without charge.
              </p>
            </div>
            <p className="text-left text-lg font-extrabold">
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              ${0}
            </p>
          </button>
        )}
        {hasFreePlan && (
          <button
            disabled
            type="submit"
            className="-mx-2 flex flex-row items-center justify-between gap-2 rounded-md bg-accent px-3 py-2 text-accent-foreground transition"
          >
            <div className="">
              <HeadingM className="flex items-center gap-2">Free</HeadingM>
              <p className="text-left text-sm text-muted-foreground">
                {currentSubscription?.plan?.description}
              </p>
            </div>
            <p className="text-left text-lg font-extrabold">
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              ${0}
            </p>
          </button>
        )}
        {plans.map((billingPlan) => (
          <form
            key={billingPlan.id}
            action={config.url.checkout}
            method="post"
            className="-mx-2"
          >
            <input type="hidden" name="price_id" value={billingPlan.id} />
            <button
              type="submit"
              className="flex w-full flex-row items-center justify-between gap-2 rounded-md px-3 py-2 text-primary transition hover:bg-accent/50 hover:text-accent-foreground"
            >
              <div className="">
                <HeadingM className="flex items-center gap-2">
                  {billingPlan.name}
                  {billingPlan.name === "Annually" && (
                    <div className="rounded-sm bg-accent/50 px-1 py-0.5 text-xs font-bold text-foreground">
                      -20%
                    </div>
                  )}
                </HeadingM>
                <p className="text-left text-sm text-muted-foreground">
                  {billingPlan.description}
                </p>
              </div>
              <p className="text-left text-lg font-extrabold">
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                ${billingPlan.priceCents! / 100}
              </p>
            </button>
          </form>
        ))}
      </CardContent>
    </Card>
  );
}
