import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../utilities";

const textareaVariants = cva(
  "flex min-h-[80px] w-full rounded-md border border-input bg-background text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "",
        inPlaceEdit:
          "min-h-0 border-none focus:border-none focus-visible:ring-offset-0 focus-visible:ring-0",
      },
      size: {
        default: "px-3 py-2",
        sm: "px-1.5 py-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, size, variant, ...props }, ref) => {
    return (
      <textarea
        className={cn(textareaVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Textarea.displayName = "Textarea";
export { Textarea };
