import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxCollection,
  RxDocument,
  RxCollectionCreator,
} from "rxdb";

import { CollectionConfig } from "../types";
import { toGid } from "../gid";

const jsonSchema = {
  title: "View Schema",
  description: "A schema describing a nomnom recipe view",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    id: {
      type: "string",
      maxLength: 36,
      final: true,
    },
    title: {
      type: "string",
    },
    filter: {
      type: "string",
    },
  },
  required: ["id"],
} as const;
const typedSchema = toTypedRxJsonSchema(jsonSchema);

type ViewType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof typedSchema>;

export type ViewDocument = RxDocument<ViewType>;
export type ViewCollection = RxCollection<ViewType>;

const schema: RxJsonSchema<ViewType> = jsonSchema;

async function setup(collection: ViewCollection) {
  // middleware
  collection.preInsert(function (data) {
    data.id = toGid("nom-nom", "View", data.id);
  }, false);
}

export const View: RxCollectionCreator<ViewType> & {
  config: CollectionConfig<ViewType>;
} = {
  config: {
    replicate: true,
    setup,
  },
  schema,
};
