import { useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { RecipeDocument, RxQuery, UserDocument, ViewDocument } from "db";

import { getDb } from "../db";

export function getTagsFromRecipes(result: RecipeDocument[]) {
  const allTags = result
    .map((recipe) => recipe.tags)
    .filter((tags): tags is string[] => tags !== undefined)
    .flat()
    .sort();

  // creating a Set to deduplicate values
  return [...new Set(allTags)];
}

export function getMealsFromRecipes(result: RecipeDocument[]) {
  const allMeals = result
    .map((recipe) => recipe.meals)
    .filter((meals): meals is string[] => meals !== undefined)
    .flat()
    .sort();

  // creating a Set to deduplicate values
  return [...new Set(allMeals)];
}

export function getCuisinesFromRecipes(result: RecipeDocument[]) {
  const allCuisines = result
    .map((recipe) => recipe.cuisines)
    .filter((cuisines): cuisines is string[] => cuisines !== undefined)
    .flat()
    .sort();

  // creating a Set to deduplicate values
  return [...new Set(allCuisines)];
}

export function getFoodTypesFromRecipes(result: RecipeDocument[]) {
  const allFoodTypes = result
    .map((recipe) => recipe.foodTypes)
    .filter((foodTypes): foodTypes is string[] => foodTypes !== undefined)
    .flat()
    .sort();

  // creating a Set to deduplicate values
  return [...new Set(allFoodTypes)];
}

export function getSeasonsFromRecipes(result: RecipeDocument[]) {
  const allSeasons = result
    .map((recipe) => recipe.seasons)
    .filter((seasons): seasons is string[] => seasons !== undefined)
    .flat()
    .sort();

  // creating a Set to deduplicate values
  return [...new Set(allSeasons)];
}

export const appLoader = async () => {
  const db = await getDb();

  const userCount = await db.users.count().exec();
  if (userCount > 1) {
    throw new Error("conflicting user data");
  }

  const userQuery = await db.users.findOne();
  const collectionsQuery = db.views.find();
  const recipesQuery = db.recipes.find();

  const [user, collections, recipes] = await Promise.all([
    userQuery.exec(),
    collectionsQuery.exec(),
    recipesQuery.exec(),
  ]);

  const tags = getTagsFromRecipes(recipes);
  const meals = getMealsFromRecipes(recipes);
  const cuisines = getCuisinesFromRecipes(recipes);
  const foodTypes = getFoodTypesFromRecipes(recipes);
  const seasons = getSeasonsFromRecipes(recipes);

  return {
    user,
    userQuery,
    recipesQuery,
    tags,
    meals,
    collectionsQuery,
    collections,
    cuisines,
    seasons,
    foodTypes,
  };
};

export const useUserDataLoader = function (): {
  user: UserDocument;
  tags: string[];
  meals: string[];
  cuisines: string[];
  foodTypes: string[];
  seasons: string[];
  collections: ViewDocument[];
} {
  const {
    user: initialUser,
    userQuery,
    tags: initialTags,
    recipesQuery,
    meals: initialMeals,
    cuisines: initialCuisines,
    foodTypes: initialFoodTypes,
    collectionsQuery,
    collections: initialCollections,
  } = useRouteLoaderData("root") as {
    user: UserDocument;
    userQuery: RxQuery<UserDocument, UserDocument>;
    tags: string[];
    meals: string[];
    cuisines: string[];
    seasons: string[];
    foodTypes: string[];
    currentUserQuery: RxQuery<UserDocument, UserDocument>;
    recipesQuery: RxQuery<RecipeDocument, RecipeDocument[]>;
    collections: ViewDocument[];
    collectionsQuery: RxQuery<ViewDocument, ViewDocument[]>;
  };

  const [tags, setTags] = useState(initialTags);
  const [seasons, setSeasons] = useState(initialTags);
  const [meals, setMeals] = useState(initialMeals);
  const [cuisines, setCuisines] = useState(initialCuisines);
  const [foodTypes, setFoodTypes] = useState(initialFoodTypes);
  const [collections, setCollections] = useState(initialCollections);
  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    const recipesQuerySubscription = recipesQuery.$.subscribe((recipes) => {
      setTags(getTagsFromRecipes(recipes));
      setMeals(getMealsFromRecipes(recipes));
      setCuisines(getCuisinesFromRecipes(recipes));
      setFoodTypes(getFoodTypesFromRecipes(recipes));
      setSeasons(getSeasonsFromRecipes(recipes));
    });

    return () => {
      recipesQuerySubscription.unsubscribe();
    };
  }, [recipesQuery]);

  useEffect(() => {
    const collectionsQuerySubscription = collectionsQuery.$.subscribe(
      (collections) => {
        setCollections(collections);
      },
    );

    return () => {
      collectionsQuerySubscription.unsubscribe();
    };
  }, [recipesQuery]);

  useEffect(() => {
    const userSubscription = user.$.subscribe((user) => {
      setUser(user);
    });

    return () => {
      userSubscription.unsubscribe();
    };
  }, [userQuery]);

  return { user, tags, meals, cuisines, collections, foodTypes, seasons };
};
