import * as React from "react";

import { HeadingXL } from "../components/Heading";
import { Page } from "../components/Page";

export function NotFound() {
  return (
    <Page>
      <HeadingXL>Sorry, something went wrong!</HeadingXL>
    </Page>
  );
}
