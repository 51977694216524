import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { RecipeDocument, RxQuery } from "db";

import { getDb } from "../../db";

export const loader = async function () {
  const db = await getDb();

  const recentlyAddedQuery = db.recipes.find({
    limit: 5,
    sort: [{ addedAt: "desc" }],
  });

  const recentlyCookedRecipesQuery = db.recipes.find({
    selector: {
      // TODO: recipes that have not been cooked yet
      // but are then marked as cooked for the first time won't be updated by this query
      lastCookedAt: { $exists: true },
    },
    limit: 5,
    sort: [{ lastCookedAt: "desc" }],
  });

  const [recentlyAddedRecipes, recentlyCookedRecipes] = await Promise.all([
    recentlyAddedQuery.exec(),
    recentlyCookedRecipesQuery.exec(),
  ]);

  return {
    recentlyAddedRecipes,
    recentlyCookedRecipes,
    recentlyAddedQuery,
    recentlyCookedRecipesQuery,
  };
};

export const useHomeDocumentsDataLoader = function (): {
  recentlyAddedRecipes: RecipeDocument[];
  recentlyCookedRecipes: RecipeDocument[];
} {
  const {
    recentlyAddedRecipes: initialRecentlyAddedRecipes,
    recentlyCookedRecipes: initialRecentlyCookedRecipes,
    recentlyAddedQuery,
    recentlyCookedRecipesQuery,
  } = useLoaderData() as {
    recentlyAddedRecipes: RecipeDocument[];
    recentlyCookedRecipes: RecipeDocument[];
    recentlyAddedQuery: RxQuery<RecipeDocument, RecipeDocument[]>;
    recentlyCookedRecipesQuery: RxQuery<RecipeDocument, RecipeDocument[]>;
  };

  const [recentlyAddedRecipes, setRecentlyAddedRecipes] = useState(
    initialRecentlyAddedRecipes,
  );

  useEffect(() => {
    const recentlyAddedQuerySubscription = recentlyAddedQuery.$.subscribe(
      (doc) => setRecentlyAddedRecipes(doc),
    );

    return () => {
      recentlyAddedQuerySubscription.unsubscribe();
    };
  }, [recentlyAddedQuery]);

  const [recentlyCookedRecipes, setRecentlyCookedRecipes] = useState(
    initialRecentlyCookedRecipes,
  );

  useEffect(() => {
    const recentlyCookedRecipesQuerySubscription =
      recentlyCookedRecipesQuery.$.subscribe((recipes) => {
        setRecentlyCookedRecipes(recipes);
      });

    return () => {
      recentlyCookedRecipesQuerySubscription.unsubscribe();
    };
  }, [recentlyCookedRecipesQuery]);

  return {
    recentlyAddedRecipes,
    recentlyCookedRecipes,
  };
};
