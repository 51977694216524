import React, { ImgHTMLAttributes, useEffect, useRef, useState } from "react";
import { Blurhash } from "react-blurhash";

import { cn } from "../../../utilities";

export type ImageProps = React.DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  blurhash?: string;
};

export function Image({ blurhash, className, src, ...imageProps }: ImageProps) {
  const ref = useRef<HTMLImageElement | null>(null);
  const [imgIsLoading, setImgIsLoading] = useState(true);
  const cached = useRef(false);

  const onLoaded = () => setImgIsLoading(false);

  useEffect(() => {
    // this is so we don't show the blur if image is cached already
    if (ref.current?.complete) {
      cached.current = true;
    }
  }, []);

  return (
    <div className="relative h-full w-full">
      {blurhash && <Blurhash hash={blurhash} width="100%" height="100%" />}
      <img
        ref={ref}
        onLoad={onLoaded}
        src={src}
        className={cn(
          className,
          "absolute left-0 top-0 h-full w-full object-cover [transition:opacity_300ms,transform_1s]",
          !imgIsLoading || cached.current ? "opacity-100" : "opacity-0",
        )}
        {...imageProps}
      />
    </div>
  );
}
