import React from "react";

import { cn } from "../utilities";

export function TopBar({ children }: React.PropsWithChildren) {
  return (
    <div
      className={cn(
        "sticky top-0 z-50 flex min-h-[52px] flex-wrap items-center justify-between gap-3 border-b bg-background/80 px-4 py-3",
        "sm:h-[52px] sm:flex-nowrap sm:gap-4 sm:py-0 sm:pt-0.5",
        "backdrop-blur-md backdrop-brightness-125 backdrop-contrast-100 backdrop-saturate-100",
        "dark:backdrop-blur dark:backdrop-brightness-75 dark:backdrop-contrast-75 dark:backdrop-saturate-200",
      )}
    >
      {children}
    </div>
  );
}
