import * as React from "react";
import { useEffect, useState } from "react";
import hotkeys from "hotkeys-js";
import { destroyDatabase } from "db";
import { v4 as uuid } from "uuid";
import normalizeUrl from "normalize-url";

import { DangerIcon, LinkIcon } from "../components/Icons";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../components/Command";
import { useDb } from "../db";
import { useCommandPalette } from "../CommandPaletteProvider";

export function CommandPalette() {
  const db = useDb();
  const { pages, open, setOpen, setPages } = useCommandPalette();
  const activePage = pages[pages.length - 1];
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    hotkeys("cmd+k, ctrl+k", () => {
      setPages(["home"]);
      setOpen((open) => !open);
    });

    hotkeys("a", (event) => {
      event.preventDefault();
      setPages([...pages, "add-recipe"]);
      setOpen((open) => !open);
    });
  }, []);

  async function addRecipe() {
    const normalizedUrl = normalizeUrl(inputValue);
    await db?.recipes.insert({
      id: uuid(),
      url: normalizedUrl,
    });
    setOpen(false);
  }

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <Command shouldFilter={activePage !== "add-recipe"}>
        <CommandInput
          autoFocus
          placeholder={
            activePage === "add-recipe"
              ? "Add recipe URL..."
              : "Type a command or search..."
          }
          onValueChange={(value) => {
            setInputValue(value);
          }}
          icon={
            activePage === "add-recipe" ? (
              <LinkIcon className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            ) : undefined
          }
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {activePage === "home" && (
            <Home addRecipe={() => setPages([...pages, "add-recipe"])} />
          )}
          {activePage === "add-recipe" && <AddRecipe addRecipe={addRecipe} />}
        </CommandList>
      </Command>
    </CommandDialog>
  );
}

function Home({ addRecipe }: { addRecipe(): void }) {
  const resetDatabase = async () => {
    await destroyDatabase();
    window.location.replace("/");
  };

  return (
    <>
      <CommandGroup heading="Recipes">
        <CommandItem onSelect={() => addRecipe()}>
          <LinkIcon className="mr-2 h-4 w-4" />
          Add recipe
        </CommandItem>
      </CommandGroup>
      <CommandGroup heading="Dangerzone">
        <CommandItem onSelect={resetDatabase}>
          <DangerIcon className="mr-2 h-4 w-4" />
          <span>Reset database</span>
        </CommandItem>
      </CommandGroup>
    </>
  );
}

function AddRecipe({ addRecipe }: { addRecipe(): void }) {
  return (
    <CommandList>
      <CommandGroup heading="Recipes">
        <CommandItem onSelect={addRecipe}>Save recipe</CommandItem>
      </CommandGroup>
    </CommandList>
  );
}
