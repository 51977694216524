export function calcServingsPresets(servings: number) {
  // const ingredientAmounts = recipe.ingredientGroups
  //   ?.map((group) =>
  //     group.ingredients?.map((ingredient) => ingredient.quantity)
  //   )
  //   .flat();

  const smaller =
    servings * 0.5 === servings - 1 ? servings * 0.25 : servings * 0.5;
  const bigger = servings * 2 === servings + 1 ? servings * 4 : servings * 2;

  return { smaller, bigger };

  // return [0.25, 0.5, 1, 2, 4].map((multiplier) => multiplier * servings);
}
