export function parseGid(gid: string): string {
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  return atob(gid).split("/").pop()!;
}

export function toGid(namespace: string, type: string, id: number | string) {
  // make sure the padding is removed, the backend does not include the padding and rxdb therefore creates a second
  // entry in the database
  return removeBase64Padding(btoa(`gid://${namespace}/${type}/${id}`));
}

function removeBase64Padding(base64: string) {
  return base64.replace(/={1,2}$/, "");
}
