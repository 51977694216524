import React, { createContext, useContext, useEffect, useState } from "react";

import { updateTextSettings } from "./actions/user";
import { useUserDataLoader } from "./utilities/user-data";

const defaultValues = {
  fontSize: {
    title: "Font size",
    options: ["sm", "base", "lg", "xl", "2xl"],
    current: "lg",
    canIncrease: true,
    canDecrease: true,
  },
  lineSpacing: {
    title: "Line spacing",
    options: ["tight", "snug", "normal", "relaxed", "loose"],
    current: "relaxed",
    canIncrease: true,
    canDecrease: true,
  },
  // lineWidth: {
  //   title: "Line width",
  //   options: ["tight", "snug", "normal", "relaxed", "loose"],
  //   current: "normal",
  //   canIncrease: true,
  //   canDecrease: true,
  // },
};

const TextSettingsContext = createContext<{
  settings: Record<
    "fontSize" | "lineSpacing",
    {
      title: string;
      options: string[];
      current: string;
      canIncrease: boolean;
      canDecrease: boolean;
    }
  >;
  onChangeSetting(
    setting: "fontSize" | "lineSpacing",
    direction: "increase" | "decrease",
  ): void;
  resetSettings(): void;
}>({
  settings: defaultValues,
  onChangeSetting: (
    _setting: "fontSize" | "lineSpacing",
    _direction: "increase" | "decrease",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetSettings: () => {},
});

export function TextSettingsProvider({ children }: React.PropsWithChildren) {
  const { user } = useUserDataLoader();

  const defaultValues = {
    fontSize: {
      title: "Font size",
      options: ["sm", "base", "lg", "xl", "2xl"],
      current: user.web?.textSettings?.fontSize ?? "lg",
      canIncrease: true,
      canDecrease: true,
    },
    lineSpacing: {
      title: "Line spacing",
      options: ["tight", "snug", "normal", "relaxed", "loose"],
      current: user.web?.textSettings?.lineSpacing ?? "relaxed",
      canIncrease: true,
      canDecrease: true,
    },
    // lineWidth: {
    //   title: "Line width",
    //   options: ["tight", "snug", "normal", "relaxed", "loose"],
    //   current: "normal",
    //   canIncrease: true,
    //   canDecrease: true,
    // },
  };

  const [textSettings, setTextSettings] = useState(defaultValues);

  useEffect(() => {
    const settings = async () => {
      await updateTextSettings(user, {
        fontSize: textSettings.fontSize.current,
        lineSpacing: textSettings.lineSpacing.current,
      });
    };
    settings();
  }, [textSettings]);

  const onChangeSetting = (
    setting: "fontSize" | "lineSpacing",
    direction: "increase" | "decrease",
  ) => {
    const options = textSettings[setting].options;
    const currentValue = textSettings[setting].current;

    const currentIndex = options.findIndex((val) => val === currentValue);

    let newIndex: undefined | number;

    if (direction === "increase" && currentIndex < options.length - 1) {
      newIndex = currentIndex + 1;
    } else if (direction === "decrease" && currentIndex > 0) {
      newIndex = currentIndex - 1;
    }

    setTextSettings((values) => {
      const newSetting = { ...values[setting] };

      if (newIndex != null) {
        newSetting.current = options[newIndex];
        newSetting.canIncrease = newIndex < options.length - 1;
        newSetting.canDecrease = newIndex > 0;
      }

      return { ...values, [setting]: newSetting };
    });
  };

  const resetSettings = () => {
    setTextSettings(() => ({ ...defaultValues }));
  };

  const value = {
    settings: textSettings,
    onChangeSetting,
    resetSettings,
  };

  return (
    <TextSettingsContext.Provider value={value}>
      {children}
    </TextSettingsContext.Provider>
  );
}

export const useTextSettings = () => {
  return useContext(TextSettingsContext);
};
