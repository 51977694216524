import * as React from "react";

import { cn } from "../utilities";

import { Button } from "./Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./Command";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { SelectedIcon, OpenPopoverIcon } from "./Icons";

export function MultiSelect({
  options,
  label,
  value = [],
  onSelect,
}: {
  options: string[];
  label: string;
  value: string[] | undefined;
  onSelect(values: string[]): void;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between px-3"
        >
          {value.length > 0 ? (
            <span className="font-normal">{value.join(", ")}</span>
          ) : (
            <span className="font-normal text-muted-foreground">{`Select ${label}…`}</span>
          )}
          <OpenPopoverIcon className="ml-1 h-4 w-4 shrink-0 stroke-[1.5]" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="max-h-[var(--radix-popover-content-available-height)] w-[200px] p-0"
        align="start"
        side="bottom"
      >
        <Command className="max-h-[var(--radix-popover-content-available-height)]">
          <CommandInput placeholder="Search options..." />
          <CommandList>
            <CommandEmpty>No options found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option}
                  onSelect={(_value) => {
                    // Note: cmdk currently lowercases values in forms for some reason, so don't use
                    // 'value' directly from the onSelect here
                    const currentValue = option;
                    if (value.includes(currentValue)) {
                      const updatedValue = value;
                      const currentValuePosition =
                        updatedValue.indexOf(currentValue);
                      updatedValue.splice(currentValuePosition, 1);
                      onSelect(updatedValue);
                    } else {
                      onSelect([...value, currentValue]);
                    }
                  }}
                >
                  <SelectedIcon
                    className={cn(
                      "mr-2 h-4 w-4",
                      value.includes(option) ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {option}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
