import { useEffect, useState } from "react";
import { Params, useLoaderData } from "react-router-dom";
import type { RecipeDocument, RxQuery } from "db";

import { getDb } from "../../db";
import { filtersToObject } from "../../utilities/filter";

export const loader = async function ({ params }: { params: Params<string> }) {
  const db = await getDb();

  const recipesQuery = params.searchTerm
    ? db.recipes.find(filtersToObject(params.searchTerm))
    : db.recipes.find();

  const [recipes] = await Promise.all([recipesQuery.exec()]);

  return { recipes, recipesQuery };
};

export const useSearchDataLoader = function (): { recipes: RecipeDocument[] } {
  const { recipes: initialRecipes, recipesQuery } = useLoaderData() as {
    recipes: RecipeDocument[];
    recipesQuery: RxQuery<RecipeDocument, RecipeDocument[]>;
  };

  const [recipes, setRecipes] = useState(initialRecipes);

  useEffect(() => {
    const recipesQuerySubscription = recipesQuery.$.subscribe(
      (updatedRecipes) => setRecipes(updatedRecipes),
    );

    return () => {
      recipesQuerySubscription.unsubscribe();
    };
  }, [recipesQuery]);

  return { recipes };
};
