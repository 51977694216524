import { useEffect, useState } from "react";
import { Params, useLoaderData } from "react-router-dom";
import { ViewDocument, RxQuery, RecipeDocument, Database } from "db";

import { getDb } from "../../db";
import { filtersToObject } from "../../utilities/filter";

export const loader = async function ({ params }: { params: Params<string> }) {
  const db = await getDb();

  const collectionQuery = db.views.findOne({ selector: { id: params.id } });
  const collection = await collectionQuery.exec();

  const collectionRecipesQuery = db.recipes.find(
    collection && collection.filter ? filtersToObject(collection.filter) : {},
  );

  const [collectionRecipes] = await Promise.all([
    collectionRecipesQuery.exec(),
  ]);

  return {
    db,
    collection,
    collectionRecipes,
  };
};

export const useCollectionDocumentDataLoader = function (): {
  collection: ViewDocument;
  collectionRecipes: RecipeDocument[];
} {
  const {
    db,
    collection: initialCollection,
    collectionRecipes: initialCollectionRecipes,
  } = useLoaderData() as {
    db: Database;
    collection: ViewDocument;
    collectionQuery: RxQuery<ViewDocument, Map<string, ViewDocument>>;
    collectionRecipes: RecipeDocument[];
    collectionRecipesQuery: RxQuery<RecipeDocument, RecipeDocument[]>;
  };

  const [collection, setCollection] = useState<ViewDocument>(initialCollection);
  const [collectionRecipes, setCollectionRecipes] = useState<RecipeDocument[]>(
    initialCollectionRecipes,
  );

  // when loader result changes, navigating from one collection to the other
  useEffect(() => {
    if (initialCollection.id !== collection.id) {
      setCollection(initialCollection);
    }
  }, [initialCollection]);

  // when updates for the currently active page arrive
  useEffect(() => {
    const collectionSubscription = collection.$.subscribe((newCollection) => {
      setCollection(newCollection);
    });

    const newRecipeQuery = db.recipes.find(
      collection && collection.filter ? filtersToObject(collection.filter) : {},
    );

    const collectionRecipesQuerySubscription = newRecipeQuery.$.subscribe(
      (collectionRecipes) => {
        setCollectionRecipes(collectionRecipes);
      },
    );

    return () => {
      collectionSubscription.unsubscribe();
      collectionRecipesQuerySubscription?.unsubscribe();
    };
  }, [collection]);

  return { collection, collectionRecipes };
};
