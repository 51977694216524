import React, { useState, useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import {
  BackIcon,
  OpenLeftSidebarIcon,
  OpenRightSidebarIcon,
  SettingsIcon,
} from "../../components/Icons";
import { cn } from "../../utilities";
import { useBreakpoints } from "../../utilities/breakpoints";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/Popover";
import { AppearanceSettings } from "../../patterns/AppearanceSettings";
import { GrowingTextarea } from "../../patterns/GrowingTextarea";
import { updateRecipeName } from "../../actions/recipe";

import { SidebarRight } from "./components/SidebarRight";
import { SidebarLeft } from "./components/SidebarLeft";
import { useRecipeDocumentDataLoader } from "./loader";
import { RecipeActionsMenu } from "./components/RecipeActionsMenu";
import { Instructions } from "./components/Instructions";
import {
  useScrollDirection,
  useScrolledToTop,
} from "./utilities/useScrollDirection";

export function Recipe() {
  const { activeBreakpoint, atLeast } = useBreakpoints();
  const { recipe, annotations, recipeEvents } = useRecipeDocumentDataLoader();

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(
    atLeast.isMd ? true : false,
  );
  const [rightSidebarOpen, setRightSidebarOpen] = useState(
    atLeast.isLg ? true : false,
  );

  const contentRef = useRef<HTMLDivElement | null>(null);
  const scrollDirection = useScrollDirection(contentRef);
  const isScrolledToTop = useScrolledToTop(contentRef);

  useEffect(() => {
    if (!atLeast.isLg) {
      setRightSidebarOpen(false);
    }
    if (!atLeast.isMd) {
      setLeftSidebarOpen(false);
    }
  }, [activeBreakpoint, atLeast]);

  const navigate = useNavigate();
  useHotkeys("esc", () => {
    navigate(-1);
  });
  useHotkeys("[", () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  });
  useHotkeys("]", () => {
    setRightSidebarOpen(!rightSidebarOpen);
  });

  return (
    <div className="flex">
      <div
        className={cn(
          "absolute z-20 overflow-hidden transition-all duration-300 ease-out",
          "md:relative md:z-auto",
          !leftSidebarOpen && "w-0 min-w-0 flex-[1_0_0%]",
          leftSidebarOpen && "w-[300px] flex-[0_0_300px] 2xl:min-w-[400px]",
        )}
      >
        <SidebarLeft
          recipe={recipe}
          onClose={() => setLeftSidebarOpen(false)}
        />
      </div>
      {/* Content */}
      <div className="relative h-screen w-full flex-auto">
        <div
          className={cn(
            "absolute top-0 z-10 h-[52px] w-full origin-top scale-y-100 bg-background transition-transform duration-200",
            scrollDirection === "DOWN" && "-translate-y-full",
            !isScrolledToTop && "border-b",
          )}
        >
          <div className={cn("flex h-full items-center justify-between px-4")}>
            <div className="flex gap-2">
              {!leftSidebarOpen && (
                <Button
                  onClick={() => {
                    setLeftSidebarOpen(true);
                  }}
                  variant="secondary"
                  size="iconSm"
                >
                  <OpenLeftSidebarIcon className="h-4 w-4" />
                </Button>
              )}
              {/* TODO: Only go back if previous page has the same host
              need to maintain our own history stack */}
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                variant="secondary"
                size="iconSm"
              >
                <BackIcon className="h-4 w-4" />
              </Button>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="secondary" size="iconSm">
                    <SettingsIcon className="h-4 w-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <AppearanceSettings />
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex gap-2">
              <RecipeActionsMenu recipe={recipe} />
              {!rightSidebarOpen && (
                <Button
                  onClick={() => {
                    setRightSidebarOpen(true);
                  }}
                  variant="secondary"
                  size="iconSm"
                >
                  <OpenRightSidebarIcon className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="relative h-screen overflow-y-auto" ref={contentRef}>
          <div className="mx-auto max-w-[600px] space-y-4 p-8 py-20 sm:space-y-6 sm:p-12 sm:py-20">
            <GrowingTextarea
              placeholder="Recipe name"
              defaultValue={recipe.name}
              size="sm"
              variant="inPlaceEdit"
              className="font-serif text-2xl font-bold sm:text-3xl"
              onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                const eventTarget = event.target;
                updateRecipeName(recipe, eventTarget.value);
              }}
            />
            {/* <HeadingXL>{recipe.name}</HeadingXL> */}
            {recipe?.imageUrl?.[0] && (
              <div className="md:-mx-20">
                <img
                  referrerPolicy="no-referrer"
                  src={recipe?.imageUrl?.[recipe?.imageUrl?.length - 1]}
                  alt={recipe.name}
                  className="h-80 w-full rounded-sm object-cover object-center"
                />
              </div>
            )}
            <Instructions recipe={recipe} annotations={annotations} />
          </div>
        </div>
      </div>
      <div
        className={cn(
          "absolute right-0 z-20 overflow-hidden transition-all duration-300 ease-out",
          "lg:relative lg:z-auto",
          !rightSidebarOpen && "w-0 min-w-0 flex-[1_0_0%]",
          rightSidebarOpen && "w-[300px] flex-[0_0_300px] 2xl:min-w-[400px]",
        )}
      >
        <SidebarRight
          annotations={annotations}
          recipe={recipe}
          recipeEvents={recipeEvents}
          onClose={() => setRightSidebarOpen(false)}
        />
      </div>
    </div>
  );
}
