import React, { useEffect } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import { Text } from "@tiptap/extension-text";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";

import { cn } from "../../utilities";

import { SingleLineDocument } from "./SingleLineDocument";
import "./ContentEditable.css";

interface ContentEditableProps {
  className?: string;
  contentEditable?: boolean;
  placeholder?: string;
  singleLine?: boolean;
  textStyle?: string;
  value?: string;
  onInput(value: string): void;
}

export function ContentEditable({
  onInput,
  contentEditable = true,
  className,
  singleLine,
  placeholder,
  textStyle,
  ...props
}: ContentEditableProps) {
  const editor = useEditor(
    {
      editable: contentEditable,
      editorProps: {
        attributes: {
          class: cn("border:none focus:border-none focus-visible:outline-none"),
        },
      },
      extensions: [
        SingleLineDocument,
        Paragraph.configure({
          HTMLAttributes: {
            class: textStyle,
          },
        }),
        Text,
        Placeholder.configure({
          placeholder,
          // emptyNodeClass:
          //   "first:before:h-0 first:before:text-gray-400 first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none",
        }),
      ],
      content: props.value,
      onUpdate: ({ editor }) => {
        onInput(editor?.getText());
      },
    },
    [contentEditable],
  );

  // Might be able to replace this by using a dependency array on the useEditor hook
  useEffect(() => {
    editor?.setOptions({ editable: contentEditable });
  }, [contentEditable]);

  return <EditorContent editor={editor} className={className} />;
}
