import dayjs, { extend } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";

extend(relativeTime);
extend(advancedFormat);

export function dateFromNow(date?: dayjs.ConfigType) {
  return dayjs(date).fromNow();
}

export function now() {
  return dayjs().toISOString();
}

export function formatDate(date?: dayjs.ConfigType) {
  return dayjs(date).format("MMMM Do, YYYY");
}

export function daysDifference(date?: dayjs.ConfigType) {
  return dayjs().diff(date, "day");
}

export function addDaysToDate(days: number, date: dayjs.ConfigType) {
  return formatDate(dayjs(date).add(days, "day"));
}
