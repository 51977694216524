import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxCollection,
  RxDocument,
  MangoQuery,
  RxCollectionCreator,
} from "rxdb";

import { toGid } from "../gid";
import { CollectionConfig } from "../types";

const jsonSchema = {
  title: "Recipe Schema",
  description: "A schema describing a nomnom recipe",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    addedAt: {
      type: "string",
      maxLength: 1024,
    },
    author: {
      type: "string",
    },
    blurhash: {
      type: "string",
    },
    cookTime: {
      type: "string",
    },
    difficulty: {
      type: "string",
    },
    cookedAt: {
      type: "array",
      items: {
        type: "string",
      },
    },
    cuisines: {
      type: "array",
      items: {
        type: "string",
      },
    },
    description: {
      type: "string",
    },
    foodTypes: {
      type: "array",
      items: {
        type: "string",
      },
    },
    id: {
      type: "string",
      maxLength: 36,
      final: true,
    },
    imageUrl: {
      type: "array",
      items: {
        type: "string",
      },
    },
    ingredientGroups: {
      type: "array",
      items: {
        type: "object",
        properties: {
          purpose: {
            type: "string",
          },
          ingredients: {
            type: "array",
            items: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                },
                size: {
                  type: "string",
                },
                amount: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      text: {
                        type: "string",
                      },
                      unit: {
                        type: "string",
                      },
                      isApproximate: {
                        type: "boolean",
                      },
                      isMultiplier: {
                        type: "boolean",
                      },
                      isRange: {
                        type: "boolean",
                      },
                      isSingular: {
                        type: "boolean",
                      },
                      quantity: {
                        type: "number",
                      },
                      quantityMax: {
                        type: "number",
                      },
                    },
                    required: [
                      "text",
                      "unit",
                      "RANGE",
                      "SINGULAR",
                      "quantity",
                      "MULTIPLIER",
                      "confidence",
                      "APPROXIMATE",
                      "quantityMax",
                    ],
                  },
                },
                comment: {
                  type: "string",
                },
                sentence: {
                  type: "string",
                },
                preparation: {
                  type: "string",
                },
              },
              required: ["name", "sentence"],
            },
          },
        },
      },
    },
    instructions: {
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          steps: {
            type: "array",
            items: {
              type: "string",
            },
          },
        },
      },
    },
    keywords: {
      type: "array",
      items: {
        type: "string",
      },
    },
    lastCookedAt: {
      type: "string",
      maxLength: 2048,
    },
    meals: {
      type: "array",
      items: {
        type: "string",
      },
    },
    name: {
      type: "string",
      maxLength: 2048,
    },
    note: {
      type: "string",
    },
    nutrition: {
      type: "object",
      properties: {
        calories: {
          type: "string",
        },
        fat: {
          type: "string",
        },
        carbohydrate: {
          type: "string",
        },
        protein: {
          type: "string",
        },
        servings: {
          type: "string",
        },
      },
    },
    preparationTime: {
      type: "string",
    },
    publishedAt: {
      type: "string",
    },
    publisher: {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        logo: {
          type: "array",
          items: {
            type: "object",
            properties: {
              url: {
                type: "string",
              },
              height: {
                type: "number",
              },
              width: {
                type: "number",
              },
            },
          },
        },
      },
    },
    seasons: {
      type: "array",
      items: {
        type: "string",
      },
    },
    status: {
      type: "string",
      final: true,
    },
    tags: {
      type: "array",
      items: {
        type: "string",
      },
    },
    totalTime: {
      type: "string",
    },
    url: {
      type: "string",
      maxLength: 2048,
    },
    video: {
      type: "array",
      items: {
        type: "object",
        properties: {
          contentUrl: {
            type: "string",
          },
          description: {
            type: "string",
          },
          embedUrl: {
            type: "string",
          },
          name: {
            type: "string",
          },
          thumbnailUrl: {
            type: "string",
          },
          uploadDate: {
            type: "string",
          },
        },
      },
    },
    website: {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        url: {
          type: "string",
        },
        icons: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
    },
    yield: {
      type: "string",
    },
  },
  required: ["id"],
  indexes: ["url", "name", "lastCookedAt", "addedAt"],
} as const;
const typedSchema = toTypedRxJsonSchema(jsonSchema);

type RecipeType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof typedSchema>;

export type RecipeDocument = RxDocument<RecipeType>;
export type RecipeCollection = RxCollection<RecipeType>;

export type RecipeDocumentQuery = MangoQuery<RecipeType>;

const schema: RxJsonSchema<RecipeType> = jsonSchema;

async function setup(collection: RecipeCollection) {
  // middleware
  collection.preInsert(function (data) {
    data.id = toGid("nom-nom", "Recipe", data.id);
    data.addedAt = new Date(Date.now()).toISOString();
  }, false);
}

export const Recipe: RxCollectionCreator<RecipeType> & {
  config: CollectionConfig<RecipeType>;
} = {
  config: {
    replicate: true,
    setup,
  },
  schema,
};

export type Ingredient = NonNullable<
  NonNullable<
    NonNullable<RecipeDocument["ingredientGroups"]>[number]
  >["ingredients"]
>[number];
