import * as React from "react";

import { Avatar, AvatarFallback, AvatarImage } from "../components/Avatar";
import { getSourceUrlFromRecipeUrl } from "../utilities/url";

export function Source({ icon, url }: { icon?: string; url: string }) {
  const normalizedUrl = getSourceUrlFromRecipeUrl(url);

  return (
    <div className="inline-flex min-h-[25px] items-center gap-2">
      <Avatar className="h-5 w-5 rounded-sm">
        {icon && <AvatarImage src={icon} />}
        <AvatarFallback>{normalizedUrl.charAt(0).toUpperCase()}</AvatarFallback>
      </Avatar>
      <div>
        <p className="line-clamp-1 leading-tight">
          {url && <span className="text-sm">{normalizedUrl}</span>}
        </p>
      </div>
    </div>
  );
}
