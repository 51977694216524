import React from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { updateCollection } from "../../actions/collections";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { HeadingM, HeadingS } from "../../components/Heading";
import { CloseIcon } from "../../components/Icons";
import { Input } from "../../components/Input";
import { Label } from "../../components/Label";
import { RecipeCard } from "../../patterns/RecipeCard";
import { RecipeFilter } from "../../patterns/RecipeFilter";
import { OrderControl } from "../../patterns/RecipeFilter/components/OrderControl";
import { RecipeList } from "../../patterns/RecipeList";
import { TopBar } from "../../patterns/TopBar";
import {
  filtersToObject,
  humanizeRecipeFilters,
  queryToRecipeFilters,
} from "../../utilities/filter";

import { CollectionActionsMenu } from "./components/CollectionActionsMenu";
import { useCollectionDocumentDataLoader } from "./loader";

export function Collection() {
  const { collection, collectionRecipes } = useCollectionDocumentDataLoader();
  const isEdit = !!useMatch("collections/:id/edit");
  const navigate = useNavigate();

  return (
    <div className="h-screen overflow-y-auto">
      <TopBar>
        <div className="flex items-center gap-4">
          <HeadingM>{collection.title}</HeadingM>
          <div className="mt-0.5 flex items-center gap-1">
            {collection.filter && (
              <>
                {humanizeRecipeFilters(
                  queryToRecipeFilters(filtersToObject(collection.filter)),
                ).map((value) => (
                  <Badge key={`${collection.title}-${value}`} variant="outline">
                    {value}
                  </Badge>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          {!!collectionRecipes.length && (
            <div className="w-[240px]">
              <OrderControl
                value={
                  queryToRecipeFilters(filtersToObject(collection.filter ?? ""))
                    .sort ?? {}
                }
                onChange={(value) => {
                  updateCollection(collection, {
                    filter: { sort: value },
                  });
                }}
              />
            </div>
          )}
          <CollectionActionsMenu collection={collection} />
        </div>
      </TopBar>
      <div className="flex flex-col overflow-y-auto sm:flex-row">
        {isEdit && (
          <div className="space-y-4 p-4 sm:sticky sm:top-0 sm:w-[250px] sm:flex-[0_0_250px] sm:pr-2">
            <div className="flex items-center justify-between">
              <HeadingS>Edit filters</HeadingS>
              <Button
                variant="secondary"
                size="iconSm"
                onClick={() => navigate(`/collections/${collection.id}`)}
              >
                <CloseIcon className="h-5 w-5" />
              </Button>
            </div>
            <div>
              <Label>Name</Label>
              <Input
                value={collection.title}
                onChange={(e) =>
                  updateCollection(collection, {
                    title: e.target.value,
                  })
                }
              />
            </div>
            <RecipeFilter
              defaultValues={queryToRecipeFilters(
                filtersToObject(collection.filter ?? ""),
              )}
              onValuesChange={(values) => {
                updateCollection(collection, {
                  filter: values,
                });
              }}
            />
          </div>
        )}
        <div className="flex-auto p-4 py-3">
          <RecipeList>
            {!collectionRecipes?.length && <>No recipes found</>}
            {collectionRecipes?.map((recipe) => (
              <RecipeCard key={recipe.id} recipe={recipe} />
            ))}
          </RecipeList>
        </div>
      </div>
    </div>
  );
}
