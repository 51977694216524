import { v4 as uuid } from "uuid";
import { RecipeEventDocument } from "db";

import { toast } from "../components/Toast";
import { getDb } from "../db";

export async function addRecipeEvent(
  event: Pick<RecipeEventDocument, "recipeId">,
  onSuccess?: () => void,
) {
  const db = await getDb();

  try {
    await db["recipe-events"].insert({
      id: uuid(),
      addedAt: new Date(Date.now()).toISOString(),
      category: "COOKED",
      recipeId: event.recipeId,
    });
    toast({ title: "Recipe event successfully added" });
    onSuccess?.();
  } catch (err) {
    toast({ title: "Recipe event could not be added" });
    console.error("Recipe event could not be added", err);
  }
}

export async function deleteRecipeEvent(
  eventId: string,
  onSuccess?: () => void,
) {
  const db = await getDb();
  const query = db["recipe-events"].find({
    selector: { id: eventId },
  });

  try {
    await query.remove();
    toast({ title: "Recipe events successfully deleted" });
    onSuccess?.();
  } catch (err) {
    toast({ title: "Recipe events could not be deleted" });
    console.error("Recipe events could not be deleted", err);
  }
}

export async function deleteRecipeEvents(
  recipeId: string,
  onSuccess?: () => void,
) {
  const db = await getDb();
  const query = db["recipe-events"].find({
    selector: { recipeId },
  });

  try {
    await query.remove();
    toast({ title: "Recipe events successfully deleted" });
    onSuccess?.();
  } catch (err) {
    toast({ title: "Recipe events could not be deleted" });
    console.error("Recipe events could not be deleted", err);
  }
}
