import React from "react";

import { Link } from "../../components/Link";
import { Alert, AlertDescription, AlertTitle } from "../../components/Alert";

import { useSubscription } from "./hook";

export function AlertExpiringTrial() {
  const { isTrial, isTrialExpired, trialDaysLeft } = useSubscription();

  return (
    isTrial &&
    !isTrialExpired && (
      <Alert>
        <AlertTitle>Great to have you on board</AlertTitle>
        <AlertDescription>
          Enjoy nomnoms free for the{" "}
          <span className="font-semibold">next {trialDaysLeft} days</span>. To
          keep savoring after the trial, simply{" "}
          <Link to="/account">subscribe</Link>.
        </AlertDescription>
      </Alert>
    )
  );
}
