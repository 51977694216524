import * as React from "react";

import { cn } from "../utilities";

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  as?: React.ElementType;
}

export function HeadingXL({
  as: Element = "h1",
  className,
  ...props
}: HeadingProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Element
      className={cn("font-serif text-2xl font-bold sm:text-3xl", className)}
      {...props}
    />
  );
}

export function HeadingL({
  as: Element = "h2",
  className,
  ...props
}: HeadingProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Element className={cn("text-xl font-bold", className)} {...props} />
  );
}

export function HeadingM({
  as: Element = "h2",
  className,
  ...props
}: HeadingProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Element
      className={cn("text-base font-medium leading-tight", className)}
      {...props}
    />
  );
}

export function HeadingS({
  as: Element = "h2",
  className,
  ...props
}: HeadingProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Element
      className={cn(
        "text-xs font-semibold uppercase tracking-wide text-muted-foreground",
        className,
      )}
      {...props}
    />
  );
}
