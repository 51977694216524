import React from "react";

import { cn } from "../utilities";

interface RecipeListProps extends React.PropsWithChildren {
  singleRow?: boolean;
}

export function RecipeList({ children, singleRow }: RecipeListProps) {
  return (
    <div
      className={cn(
        "grid justify-stretch gap-4",
        !singleRow && "grid-cols-[repeat(auto-fill,minmax(280px,1fr))]",
        singleRow &&
          "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 md:max-lg:[&>*:nth-child(1n+4)]:hidden sm:max-2xl:[&>*:nth-child(1n+5)]:hidden",
      )}
    >
      {children}
    </div>
  );
}
