import React from "react";

import { Button } from "../../components/Button";
import { HeadingS } from "../../components/Heading";
import { AddIcon, SubstractIcon } from "../../components/Icons";
import { Label } from "../../components/Label";
import { useTextSettings } from "../../TextSettingsProvider";
import { ThemeSwitcher } from "../ThemeSwitcher";

export function AppearanceSettings() {
  const { settings, onChangeSetting, resetSettings } = useTextSettings();

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <HeadingS>Theme</HeadingS>
        <ThemeSwitcher />
      </div>
      <div className="space-y-1">
        <div className="flex items-center justify-between">
          <HeadingS>Text settings</HeadingS>
          <Button
            size="linkXs"
            variant="monochromeLink"
            onClick={resetSettings}
            className="-mt-[4px]"
          >
            Reset
          </Button>
        </div>
        <div className="">
          {Object.entries(settings).map(([key, value]) => {
            return (
              <div
                key={value.title}
                className="flex items-center justify-between border-b py-1.5 last:border-b-0"
              >
                <Label>{value.title}</Label>
                <div className="flex items-center gap-3">
                  <span className="text-xs text-muted-foreground">
                    {value.current}
                  </span>
                  <div className="flex gap-1">
                    <Button
                      size="iconSm"
                      variant="secondary"
                      disabled={!value.canDecrease}
                      onClick={() =>
                        onChangeSetting(
                          key as "fontSize" | "lineSpacing",
                          "decrease",
                        )
                      }
                    >
                      <SubstractIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      size="iconSm"
                      variant="secondary"
                      disabled={!value.canIncrease}
                      onClick={() =>
                        onChangeSetting(
                          key as "fontSize" | "lineSpacing",
                          "increase",
                        )
                      }
                    >
                      <AddIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
