import React from "react";

import { buttonVariants } from "../../../components/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { config } from "../../../config";

export function DeleteAccount() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Delete account</CardTitle>
        <CardDescription>
          Deleting your account will irreversibly delete all of your data,
          including your recipes, recipe edits like tags, notes, highlights, and
          more.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <a
          href={config.url.userDelete}
          className={buttonVariants({
            variant: "destructive",
            size: "sm",
          })}
        >
          Delete my account
        </a>
      </CardContent>
    </Card>
  );
}
