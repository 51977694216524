import * as React from "react";
import { ViewDocument } from "db";
import { useNavigate } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../components/DropdownMenu";
import { Button } from "../../../components/Button";
import { ActionsIcon } from "../../../components/Icons";
import { deleteCollection } from "../../../actions/collections";

interface RecipeActionsMenuProps {
  collection: ViewDocument;
}
export function CollectionActionsMenu({ collection }: RecipeActionsMenuProps) {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="iconSm">
          <ActionsIcon className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuItem
          onSelect={() => navigate(`/collections/${collection.id}/edit`)}
        >
          <span>Edit filters</span>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <span>Share collection</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onSelect={() =>
            deleteCollection(collection, () => navigate("/collections"))
          }
          className="focus:bg-destructive"
        >
          <span>Delete collection</span>
          <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
