import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxCollection,
  RxDocument,
  RxCollectionCreator,
  RxQuery,
} from "rxdb";

import { CollectionConfig } from "../types";
import { toGid } from "../gid";

const jsonSchema = {
  title: "Annotation Schema",
  description: "A schema describing a recipe annotation",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    content: {
      type: "string",
    },
    createdAt: {
      type: "string",
      final: true,
    },
    id: {
      type: "string",
      maxLength: 36,
      final: true,
    },
    note: {
      type: "string",
    },
    range: {
      type: "string",
    },
    recipeId: {
      type: "string",
      ref: "recipe",
    },
  },
  required: ["id", "recipeId"],
} as const;
const typedSchema = toTypedRxJsonSchema(jsonSchema);

type AnnotationType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof typedSchema
>;

export type AnnotationDocument = RxDocument<AnnotationType>;
export type AnnotationCollection = RxCollection<AnnotationType>;

export type AnnotationDocumentQuery = RxQuery<
  AnnotationDocument,
  AnnotationDocument[]
>;

const schema: RxJsonSchema<AnnotationType> = jsonSchema;

async function setup(collection: AnnotationCollection) {
  // middleware
  collection.preInsert(function (data) {
    data.id = toGid("nom-nom", "Annotation", data.id);
  }, false);
}

export const Annotation: RxCollectionCreator<AnnotationType> & {
  config: CollectionConfig<AnnotationType>;
} = {
  schema,
  config: {
    replicate: true,
    setup,
  },
};
