import React, { useState } from "react";

import { useCommandPalette } from "../../CommandPaletteProvider";
import { HeadingM } from "../../components/Heading";
import { NoRecipeCard, RecipeCard } from "../../patterns/RecipeCard";
import {
  OrderControl,
  type Value,
} from "../../patterns/RecipeFilter/components/OrderControl";
import { RecipeList } from "../../patterns/RecipeList";
import { TopBar } from "../../patterns/TopBar";

import { useLibraryDataLoader } from "./loader";

export function Library() {
  const [sort, setSort] = useState<Value>({ order: "addedAt", by: "desc" });
  const { recipes } = useLibraryDataLoader(
    sort.order && sort.by ? { [sort.order]: sort.by } : undefined,
  );
  const { openPalettePage } = useCommandPalette();

  return (
    <>
      <TopBar>
        <div className="flex items-center gap-4">
          <HeadingM>Library</HeadingM>
        </div>
        {!!recipes.length && (
          <div className="w-[240px]">
            <OrderControl value={sort} onChange={setSort} />
          </div>
        )}
      </TopBar>
      <div className="px-4 py-3 pt-3">
        <RecipeList>
          {recipes.length ? (
            recipes.map((recipe) => (
              <RecipeCard key={recipe.id} recipe={recipe} />
            ))
          ) : (
            <>
              <NoRecipeCard
                onClick={() => {
                  openPalettePage(["add-recipe"]);
                }}
              />
              <NoRecipeCard className="opacity-50" />
              <NoRecipeCard className="opacity-30" />
              <NoRecipeCard className="opacity-10" />
            </>
          )}
        </RecipeList>
      </div>
    </>
  );
}
