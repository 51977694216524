import React, { PropsWithChildren } from "react";
import { useForm } from "react-hook-form";

import { addCollection } from "../../../actions/collections";
import { Button } from "../../../components/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/Dialog";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";

export function AddCollectionDialog({
  children,
  filter,
  onSuccess,
}: PropsWithChildren<{
  filter?: string;
  onSuccess: Parameters<typeof addCollection>[1];
}>) {
  const { handleSubmit, getValues, register } = useForm({
    defaultValues: { title: "My collection" },
  });

  const onSubmit = () => {
    addCollection(
      {
        filter,
        title: getValues("title"),
      },
      onSuccess,
    );
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create collection</DialogTitle>
          <DialogDescription>
            Add a title for your new collection
          </DialogDescription>
        </DialogHeader>
        <form id="createCollection" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-2">
            <Label htmlFor="collectionTitle" className="hidden">
              Collection title
            </Label>
            <Input id="collectionTitle" {...register("title")} />
          </div>
        </form>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="submit" form="createCollection">
              Save collection
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
