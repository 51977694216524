import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

import { Button } from "./components/Button";
import { HeadingM, HeadingXL } from "./components/Heading";

export function Error() {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error(error);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <HeadingXL>Ooops!</HeadingXL>
      <div className="flex gap-3 pb-6">
        <HeadingM>Seems like something went wrong.</HeadingM>
      </div>
      <Button
        onClick={() => {
          navigate(0);
        }}
        className="mb-10"
      >
        Reload page
      </Button>
    </div>
  );
}
