import * as React from "react";
import { RecipeDocument } from "db";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../components/DropdownMenu";
import { Button } from "../../../components/Button";
import { ActionsIcon } from "../../../components/Icons";
import { deleteRecipe } from "../../../actions/recipe";
import { addRecipeEvent } from "../../../actions/recipe-event";

interface RecipeActionsMenuProps {
  recipe: RecipeDocument;
}
export function RecipeActionsMenu({ recipe }: RecipeActionsMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="iconSm">
          <ActionsIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuItem
          onSelect={() => {
            addRecipeEvent({ recipeId: recipe.id });
          }}
        >
          Mark as made
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem disabled={!recipe.url}>
          <a href={recipe.url} target="_blank" rel="noopener noreferrer">
            Open original
          </a>
          <DropdownMenuShortcut>O</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <span>View public document</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onSelect={() => deleteRecipe(recipe)}
          className="focus:bg-destructive"
        >
          <span>Delete</span> <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
