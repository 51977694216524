export class Deferred<T = any> {
  catch: Promise<T>["catch"];
  reject: (error: Error) => void;
  resolve: (arg: T) => void;
  status: "not-ready" | "pending" | "rejected" | "resolved" = "pending";
  then: Promise<T>["then"];

  _promise: Promise<T>;

  constructor() {
    this.resolve = () => {
      throw this.getEarlyCallError("resolve");
    };
    this.reject = () => {
      throw this.getEarlyCallError("reject");
    };

    this._promise = new Promise((resolve, reject) => {
      this.resolve = (...args) => {
        if (this.status === "rejected") {
          throw new Error("Already rejected");
        }
        if (this.status === "resolved") {
          throw new Error("Already resolved");
        }
        const result = resolve(...args);
        this.status = "resolved";
        return result;
      };
      this.reject = (...args) => {
        if (this.status === "rejected") {
          throw new Error("Already rejected");
        }
        if (this.status === "resolved") {
          throw new Error("Already resolved");
        }
        const result = reject(...args);
        this.status = "rejected";
        return result;
      };
    });
    this.status = "pending";
    this.then = this._promise.then.bind(this._promise);
    this.catch = this._promise.catch.bind(this._promise);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this[Symbol.toStringTag] = "Promise";
  }

  private getEarlyCallError(name: string): Error {
    return new Error(`${name} called before it was set`);
  }
}
