import originalPluralize from "pluralize";

// English
// addUncountableRule("g");
// addUncountableRule("ml");
// addUncountableRule("ml");
// addUncountableRule("kg");
// addUncountableRule("l");
// addUncountableRule("tbs");
// addUncountableRule("large");
// addUncountableRule("small");
// addUncountableRule("medium");
// addUncountableRule("tsp");

// German
// addUncountableRule("EL");
// addUncountableRule("TL");
// addUncountableRule("Stück");
// addUncountableRule("Dose");
// addUncountableRule("Pack.");
// addUncountableRule("Packung");
// addUncountableRule("n. B.");
// addUncountableRule("n.B.");
// addUncountableRule("Prise");
// addUncountableRule("Schuss");
// addUncountableRule("Msp.");
// addUncountableRule("Messerspitze");
// addUncountableRule("Messerspitzen");
// addUncountableRule("Esslöffel");
// addUncountableRule("Teelöffel");
// addUncountableRule("Tasse");
// addUncountableRule("Tassen");
// addUncountableRule("Bund");

const whitelist = [
  "teaspoon",
  "teaspoons",
  "tablespoon",
  "tablespoons",
  "cup",
  "cups",
];

export function pluralize(word: string, count: number | string) {
  if (!whitelist.includes(word)) {
    return word;
  }

  if (typeof count === "string") {
    const numberCount = parseInt(count);
    return originalPluralize(word, numberCount);
  }
  return originalPluralize(word, count);
}
