// also update apps/mobile/src/lib/duration.ts

import dayjs, { extend } from "dayjs";
import duration from "dayjs/plugin/duration";

extend(duration);

// ISO 8601 durations
export function formatDuration(value?: string, fallback?: string) {
  if (value) {
    const duration = dayjs.duration(value);
    const days = duration.days();
    return `${
      days > 0 ? `${days}d` : ""
    } ${duration.hours()}h ${duration.minutes()}m`;
  }

  return fallback ?? "–";
}
