import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

import { Subscription } from "../../patterns/Subscription";
import { Button, buttonVariants } from "../../components/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/Form";
import { HeadingM } from "../../components/Heading";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/Card";
import { Page } from "../../components/Page";
import { Input } from "../../components/Input";
import { Label } from "../../components/Label";
import { Switch } from "../../components/Switch";
import { SignOutIcon } from "../../components/Icons";
import { config } from "../../config";
import { AppearanceSettings } from "../../patterns/AppearanceSettings";
import { useUserDataLoader } from "../../utilities/user-data";
import { TopBar } from "../../patterns/TopBar";
import { updateNotificationSettings } from "../../actions/user";

import { NotificationSettings } from "./components/NotificationSettings";
import { DeleteAccount } from "./components/DeleteAccount";
// import { ReportIssue } from "./components/ReportIssue";

export function Account() {
  const { user } = useUserDataLoader();

  const formSchema = z.object({
    // username: z.string().min(2, {
    //   message: "Username must be at least 2 characters.",
    // }),
    email: z
      .string()
      .min(1, {
        message: "Email is required.",
      })
      .email("This is not a valid email."),
    firstName: z.string().min(1, {
      message: "First name is required.",
    }),
    lastName: z.string().min(1, {
      message: "Last name is required.",
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // username: "",
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    user.modify((currentUser) => {
      currentUser.email = values.email;
      currentUser.firstName = values.firstName;
      currentUser.lastName = values.lastName;

      return currentUser;
    });
  }

  return (
    <>
      <TopBar>
        <HeadingM>Account</HeadingM>
        <a
          href={config.url.userSignOut}
          className={buttonVariants({ size: "sm", variant: "secondary" })}
        >
          <span className="flex items-center gap-1.5">
            <SignOutIcon className="h-4 w-4" />
            Sign out
          </span>
        </a>
      </TopBar>
      <Page>
        <div className="space-y-3">
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-3 xl:grid-cols-4">
            <Card>
              <CardHeader>
                <CardTitle>Profile</CardTitle>
              </CardHeader>
              <CardContent>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4"
                  >
                    {/* <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                /> */}
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Fist name</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="lastName"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Last name</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button type="submit" size="sm" className="w-full">
                      Submit
                    </Button>
                  </form>
                </Form>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Appearance</CardTitle>
                <CardDescription>Personalize your experience.</CardDescription>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  <AppearanceSettings />
                </CardDescription>
              </CardContent>
            </Card>
            <Subscription />
            <NotificationSettings
              enabled={user.allowNotifications}
              onSettingChange={(enabled) =>
                updateNotificationSettings(user, enabled)
              }
            />
            <Card>
              <CardHeader>
                <CardTitle>Cookie settings</CardTitle>
                <CardDescription>
                  Manage your cookie settings here.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-6">
                <div className="flex items-center justify-between space-x-2">
                  <Label
                    htmlFor="necessary"
                    className="flex flex-col space-y-1"
                  >
                    <span>Strictly Necessary</span>
                    <span className="font-normal leading-snug text-muted-foreground">
                      These cookies are essential in order to use the website
                      and use its features.
                    </span>
                  </Label>
                  <Switch id="necessary" defaultChecked />
                </div>
                <div className="flex items-center justify-between space-x-2">
                  <Label
                    htmlFor="functional"
                    className="flex flex-col space-y-1"
                  >
                    <span>Functional Cookies</span>
                    <span className="font-normal leading-snug text-muted-foreground">
                      These cookies allow the website to provide personalized
                      functionality.
                    </span>
                  </Label>
                  <Switch id="functional" />
                </div>
                <div className="flex items-center justify-between space-x-2">
                  <Label
                    htmlFor="performance"
                    className="flex flex-col space-y-1"
                  >
                    <span>Performance Cookies</span>
                    <span className="font-normal leading-snug text-muted-foreground">
                      These cookies help to improve the performance of the
                      website.
                    </span>
                  </Label>
                  <Switch id="performance" />
                </div>
              </CardContent>
            </Card>
            {/* <ReportIssue /> */}
            <DeleteAccount />
          </div>
        </div>
      </Page>
    </>
  );
}
