import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { RecipeDocument, Database, MangoQuerySortPart } from "db";

import { getDb } from "../../db";

export const loader = async function () {
  const db = await getDb();

  const recipesQuery = db.recipes.find({
    sort: [{ addedAt: "desc" }],
  });

  const recipes = await recipesQuery.exec();

  return { db, recipes };
};

export const useLibraryDataLoader = (
  sort?: MangoQuerySortPart<RecipeDocument>,
) => {
  const { db, recipes: initialRecipes } = useLoaderData() as {
    db: Database;
    recipes: RecipeDocument[];
  };

  const [recipes, setRecipes] = useState(initialRecipes);

  const recipesQuery = db.recipes.find({ sort: sort ? [sort] : undefined });

  useEffect(() => {
    const recipesQuerySubscription = recipesQuery.$.subscribe((recipes) =>
      setRecipes(recipes),
    );

    return () => {
      recipesQuerySubscription.unsubscribe();
    };
  }, [recipesQuery]);

  return { recipes: recipes };
};
