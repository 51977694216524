import React from "react";

import { Theme, useTheme } from "../ThemeProvider";
import { RadioGroup, RadioGroupItem } from "../components/RadioGroup";
import { Label } from "../components/Label";
import {
  LightThemeIcon,
  DarkThemeIcon,
  SystemThemeIcon,
} from "../components/Icons";

export function ThemeSwitcher() {
  const { setTheme, theme } = useTheme();

  return (
    <RadioGroup
      defaultValue={theme}
      className="grid grid-cols-3 gap-2"
      onValueChange={(value: Theme) => setTheme(value)}
    >
      <div>
        <RadioGroupItem value="light" id="light" className="peer sr-only" />
        <Label
          htmlFor="light"
          className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-3 py-2 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
        >
          <LightThemeIcon className="mb-2 h-5 w-5" />
          Light
        </Label>
      </div>
      <div>
        <RadioGroupItem value="dark" id="dark" className="peer sr-only" />
        <Label
          htmlFor="dark"
          className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-3 py-2 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
        >
          <DarkThemeIcon className="mb-2 h-5 w-5" />
          Dark
        </Label>
      </div>
      <div>
        <RadioGroupItem value="system" id="system" className="peer sr-only" />
        <Label
          htmlFor="system"
          className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-3 py-2 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
        >
          <SystemThemeIcon className="mb-2 h-5 w-5" />
          System
        </Label>
      </div>
    </RadioGroup>
  );
}
