// We serialize the filter string
// * to save it for a collection
// * for the URL when searching

// normalized values -> mango query object
// mango search object -> url
// url -> mango search object
// mango query object -> normalized values

import { MangoQueryOperators, RecipeDocumentQuery } from "db";

export type RecipeFilterSortOrder =
  | "addedAt"
  | "lastCookedAt"
  | "website.name"
  | "name";

export type RecipeFilterSortBy = "desc" | "asc";

type Filters =
  | "searchTerm"
  | "cuisines"
  | "foodTypes"
  | "meals"
  | "seasons"
  | "tags"
  | "sort";

export type RecipeFilters = {
  searchTerm?: string;
  cuisines?: string[];
  foodTypes?: string[];
  meals?: string[];
  seasons?: string[];
  tags?: string[];
  sort?: { order?: RecipeFilterSortOrder; by?: RecipeFilterSortBy };
};

const filters: Record<Filters, string> = {
  searchTerm: "Search term",
  cuisines: "Cuisine",
  foodTypes: "Food type",
  meals: "Meal",
  seasons: "Season",
  tags: "Tag",
  sort: "Sort",
};

export const defaultRecipeFilters: RecipeFilters = {
  searchTerm: "",
  cuisines: [],
  foodTypes: [],
  meals: [],
  seasons: [],
  tags: [],
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function humanizeRecipeFilters(values: RecipeFilters): string[] {
  const mappableFilters = Object.entries(values) as Entries<
    Required<RecipeFilters>
  >;

  return mappableFilters
    .map(([key, value]) => {
      if (key === "sort") {
        return null;
      }
      if (typeof value === "string") {
        return `${filters[key]}: ${value}`;
      }

      if (Array.isArray(value)) {
        return `${filters[key]}: ${value.join(", ")}`;
      }

      if (typeof value === "object") {
        return `${key}: ${Object.values(value).join(" ")}`;
      }

      return `${filters[key]}: ${JSON.stringify(value)}`;
    })
    .filter((f: string | null): f is string => f !== null);
}

export function recipeFiltersToQuery(
  values: RecipeFilters,
): RecipeDocumentQuery {
  const selector: Record<string, any> = {};
  const sort: any[] = [];

  // FILTERS
  // name
  if (values.searchTerm && values.searchTerm != "") {
    const regex = `.*${values.searchTerm}.*`;
    selector.name = {
      $regex: regex,
      $options: "i",
    };
  }

  // cuisines
  if (values.cuisines && values.cuisines.length > 0) {
    selector.cuisines = { $in: values.cuisines };
  }

  // food types
  if (values.foodTypes && values.foodTypes.length > 0) {
    selector.foodTypes = { $in: values.foodTypes };
  }

  // meal
  if (values.meals && values.meals.length > 0) {
    selector.meals = { $in: values.meals };
  }

  // seasons
  if (values.seasons && values.seasons.length > 0) {
    selector.seasons = { $in: values.seasons };
  }

  // tags
  if (values.tags && values.tags.length > 0) {
    selector.tags = { $in: values.tags };
  }

  // SORTING
  if (values.sort?.order) {
    sort.push({
      [values.sort?.order]: values.sort?.by,
    });
  }

  return { selector, sort };
}

export function queryToRecipeFilters(
  query: RecipeDocumentQuery,
): RecipeFilters {
  const recipeFilters: RecipeFilters = {};

  // SELECTOR
  // name
  if (query.selector?.name) {
    const searchTerm = (
      query.selector?.name as MangoQueryOperators<string>
    ).$regex?.toString();
    recipeFilters.searchTerm = searchTerm?.substring(3, searchTerm.length - 4);
  }

  // cuisines
  if (query.selector?.cuisines) {
    recipeFilters.cuisines = (
      query.selector?.cuisines as MangoQueryOperators<string>
    ).$in;
  }

  // cuisines
  if (query.selector?.foodTypes) {
    recipeFilters.foodTypes = (
      query.selector?.foodTypes as MangoQueryOperators<string>
    ).$in;
  }

  // meals
  if (query.selector?.meals) {
    recipeFilters.meals = (
      query.selector?.meals as MangoQueryOperators<string>
    ).$in;
  }

  // seasons
  if (query.selector?.seasons) {
    recipeFilters.seasons = (
      query.selector?.seasons as MangoQueryOperators<string>
    ).$in;
  }

  // tags
  if (query.selector?.tags) {
    recipeFilters.tags = (
      query.selector?.tags as MangoQueryOperators<string>
    ).$in;
  }

  // SORT
  if (query.sort?.[0]) {
    const firstEntry = Object.entries(query.sort?.[0])[0];
    recipeFilters.sort = {
      // TODO: should add type guard here
      order: firstEntry[0] as RecipeFilterSortOrder,
      by: firstEntry[1],
    };
  }

  return recipeFilters;
}

export function filtersToObject(searchTerm: string): RecipeDocumentQuery {
  return JSON.parse(searchTerm);
}

export function filtersToString(searchTerm: RecipeDocumentQuery) {
  return JSON.stringify(searchTerm);
}
