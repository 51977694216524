import React, { useEffect } from "react";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/Form";
import { Input } from "../../components/Input";
import { MultiSelect } from "../../components/MultiSelect";
import {
  defaultRecipeFilters,
  type RecipeFilters,
} from "../../utilities/filter";
import { useUserDataLoader } from "../../utilities/user-data";

const FormSchema = z.object({
  searchTerm: z.string().optional(),
  cuisines: z.array(z.string()).optional(),
  foodTypes: z.array(z.string()).optional(),
  meals: z.array(z.string()).optional(),
  seasons: z.array(z.string()).optional(),
  tags: z.array(z.string()).optional(),
});

interface RecipeFilterProps {
  defaultValues?: RecipeFilters;
  onValuesChange(value: z.infer<typeof FormSchema>): void;
}

export function RecipeFilter({
  defaultValues,
  onValuesChange,
}: RecipeFilterProps) {
  const { tags, meals, cuisines, foodTypes, seasons } = useUserDataLoader();

  const form = useForm<z.infer<typeof FormSchema>>({
    progressive: true,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resolver: zodResolver(FormSchema)!,
    defaultValues: defaultValues ?? defaultRecipeFilters,
  });

  const formValues = useWatch({
    control: form.control,
    defaultValue: defaultValues,
  });

  useEffect(() => {
    onValuesChange(formValues);
  }, [formValues]);

  function onSubmit(_data: z.infer<typeof FormSchema>) {
    console.log("submit");
  }

  return (
    <div className="space-y-2">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="searchTerm"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Search term</FormLabel>
                <FormControl>
                  <Input placeholder="I am looking for…" autoFocus {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cuisines"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Cuisine</FormLabel>
                <FormControl>
                  <MultiSelect
                    options={cuisines}
                    label="cuisine"
                    onSelect={(newValues) =>
                      form.setValue("cuisines", newValues)
                    }
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="foodTypes"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Food type</FormLabel>
                <FormControl>
                  <MultiSelect
                    options={foodTypes}
                    label="food type"
                    onSelect={(newValues) =>
                      form.setValue("foodTypes", newValues)
                    }
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="seasons"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Season</FormLabel>
                <FormControl>
                  <MultiSelect
                    options={seasons}
                    label="season"
                    onSelect={(newValues) =>
                      form.setValue("seasons", newValues)
                    }
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="meals"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Meal</FormLabel>
                <FormControl>
                  <MultiSelect
                    options={meals}
                    label="meal"
                    onSelect={(newValues) => form.setValue("meals", newValues)}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tags"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="">Tags</FormLabel>
                <FormControl>
                  <MultiSelect
                    options={tags}
                    label="tags"
                    onSelect={(newValues) => form.setValue("tags", newValues)}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
}
