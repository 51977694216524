import React, { RefObject, useEffect, useRef, useState } from "react";
import { AnnotationDocument } from "db/collections";
import { flushSync } from "react-dom";

import { RangyHighlight, RangyHighlighter } from "../../../../rangy";
import rangy from "../../../../utilities/rangy";

// DO NOT CHANGE THESE VALUES WITHOUT A DATABASE MIGRATION!!
// Both, class_name and container_id are used during the serialization of highlights (ranges) for an annotation. We can
// only restore them when these values match.
export const ANNOTATION_CLASS_NAME = "highlight";
export const ANNOTATION_CONTAINER_ID = "highlighter";

export function serialize(highlight: RangyHighlight) {
  const characterRange = highlight.characterRange;

  const parts = [characterRange.start, characterRange.end];
  return parts.join("$");
}

export function deserialize(
  highlighter: RangyHighlighter,
  annotations: AnnotationDocument[],
) {
  const serialized = [
    "type:textContent",
    ...annotations.map((annotation, index) =>
      [
        ...(annotation.range?.split("$") || []),
        String(index + 1),
        ANNOTATION_CLASS_NAME,
        ANNOTATION_CONTAINER_ID,
      ].join("$"),
    ),
  ].join("|");

  if (serialized) {
    highlighter.deserialize(serialized);
  }
}

export function useAnnotations(
  ref: RefObject<HTMLElement>,
  annotations: AnnotationDocument[],
) {
  const highlighter = useRef<RangyHighlighter | null>(null);
  const [currentAnnotationElement, setCurrentAnnotationElement] =
    useState<HTMLElement | null>(null);

  // register outside highlighter click event (e.g. to hide the annotation context menu)
  useEffect(() => {
    const outsideMenuClickListener = (event: MouseEvent) => {
      // prevent closing of popups when clicking on any interactive elements
      const isHighlight = (event.target as HTMLElement).closest(
        `.${ANNOTATION_CLASS_NAME}`,
      );
      const isPopover = (event.target as HTMLElement).closest(
        "[data-radix-popper-content-wrapper]",
      );
      if (isHighlight || isPopover) {
        return true;
      }

      if (
        currentAnnotationElement &&
        event.currentTarget !== currentAnnotationElement
      ) {
        setCurrentAnnotationElement(null);
      }
    };
    document.addEventListener("mouseup", outsideMenuClickListener);

    return () => {
      document.removeEventListener("mouseup", outsideMenuClickListener);
    };
  }, [currentAnnotationElement]);

  useEffect(() => {
    // save a copy to listeners, so that we can clean up at the end
    const annotationListeners = new Map<
      HTMLElement,
      (event: MouseEvent) => void
    >();
    const newHighlighter = rangy.createHighlighter(
      ref.current?.ownerDocument,
      "textContent",
    );
    const classApplier = rangy.createClassApplier(ANNOTATION_CLASS_NAME, {
      elementTagName: "mark",
      normalize: true,
      ignoreWhiteSpace: true,
      useExistingElements: true,
      onElementCreate: (element) => {
        // register events
        function listener(event: MouseEvent) {
          if (event.target === event.currentTarget) {
            setCurrentAnnotationElement(element);
          }
        }

        annotationListeners.set(element, listener);
        element.addEventListener("click", listener);

        // when created, make sure to show context menu
        setCurrentAnnotationElement(element);
      },
    });
    newHighlighter.addClassApplier(classApplier);

    // save highlighter to ref
    highlighter.current = newHighlighter;

    return () => {
      // cleanup listeners
      for (const [element, listener] of annotationListeners) {
        element.removeEventListener("click", listener);
      }

      // TODO: cleanup highlighter? How can we be sure the highlighter is destroyed/removed, that there are no
      //  memory leaks, etc.
    };
  }, []);

  // restore (deserialize) highlights from annotations, also react to changes from query result
  const highlightToAnnotationMap = useRef<Map<string, AnnotationDocument>>(
    new Map(),
  );
  useEffect(() => {
    if (annotations.length < 1) {
      return;
    }

    if (highlighter.current == null) {
      return;
    }

    // remove annotations that are no longer present
    for (const [id, annotation] of highlightToAnnotationMap.current) {
      const result = annotations.find((a) => a.id === annotation.id);
      if (!result) {
        const highlight = highlighter.current?.highlights.find(
          (h) => h.id === id,
        );
        if (highlight) {
          flushSync(() => {
            setCurrentAnnotationElement(null);
          });

          highlighter.current?.removeHighlights([highlight]);
        }
      }
    }

    // deserialize, automatically injects the marker tags
    deserialize(highlighter.current, annotations);

    // now connect annotations with highlights, so that we can interact with highlights and modify annotations
    const pairs: [string, AnnotationDocument][] = annotations
      .reverse()
      .map((annotation, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return [highlighter.current!.highlights[index].id!, annotation];
      });
    highlightToAnnotationMap.current = new Map(pairs);
  }, [annotations]);

  // This is a utility for Rangy to create the highlight from the current selection
  function highlightSelection() {
    return highlighter.current?.highlightSelection(ANNOTATION_CLASS_NAME, {
      exclusive: true,
      containerElementId: ANNOTATION_CONTAINER_ID,
    })[0];
  }

  async function annotate(
    onAnnotationCreated: (
      highlight: RangyHighlight,
    ) => Promise<AnnotationDocument>,
  ) {
    // make sure our portal is gone by the time we manipulate the DOM directly
    flushSync(() => {
      setCurrentAnnotationElement(null);
    });

    const selection = rangy.getSelection();

    if (selection.type === "Range") {
      const currentHighlight = highlightSelection();
      if (currentHighlight) {
        const annotation = await onAnnotationCreated(currentHighlight);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        highlightToAnnotationMap.current.set(currentHighlight.id!, annotation);
      }
    }
  }

  function isHighlighted(element: HTMLElement) {
    const range = rangy.createRange(ref.current?.ownerDocument);
    range.selectNodeContents(element);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (const highlight of highlighter.current!.highlights!) {
      if (highlight.getText() === element.textContent) {
        return true;
      }
    }

    return false;
  }

  async function removeAnnotation(
    element: HTMLElement,
    onAnnotationRemoved: (annotation: AnnotationDocument) => Promise<void>,
  ) {
    const range = rangy.createRange(ref.current?.ownerDocument);
    range.selectNodeContents(element);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (const highlight of highlighter.current!.highlights!) {
      if (highlight.getText() === element.textContent) {
        // make sure our portal is gone by the time we manipulate the DOM directly
        flushSync(() => {
          setCurrentAnnotationElement(null);
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const annotation = highlightToAnnotationMap.current.get(highlight.id!);
        if (annotation) {
          await onAnnotationRemoved(annotation);
        }

        highlighter.current?.removeHighlights([highlight]);
        rangy.getSelection().removeAllRanges();

        return true;
      }
    }

    return false;
  }

  async function removeCurrent() {
    console.log("removeCurrent", currentAnnotationElement);
    if (!currentAnnotationElement) {
      console.info("can't remove, current annotation element is null");
      return false;
    }

    const highlight = highlighter.current?.getHighlightForElement(
      currentAnnotationElement,
    );
    if (!highlight) {
      console.info("can't remove, can't find highlight for current element");
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const annotation = highlightToAnnotationMap.current.get(highlight.id!);
    if (!annotation) {
      console.info("can't remove, can't find annotation for current element");
      return false;
    }

    console.info("remove from database");
    await annotation.remove();

    console.info("remove from map");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    highlightToAnnotationMap.current.delete(highlight.id!);

    // make sure to free the portal before manipulating dom
    flushSync(() => {
      setCurrentAnnotationElement(null);
    });

    console.info("remove the highlight");
    highlighter.current?.removeHighlights([highlight]);

    console.info("reset ranges");
    rangy.getSelection().removeAllRanges();

    return true;
  }

  function annotateWithSelection(
    onAnnotationCreated: (
      highlight: RangyHighlight,
    ) => Promise<AnnotationDocument>,
  ) {
    return (event: React.MouseEvent) => {
      // only register select action, when operating on actual content.
      // 1. Must be an element within the highlighter
      // 2. Cannot be a highlight itself (or any descendants)
      if (
        !event.currentTarget.contains(event.target as HTMLElement) ||
        (event.target as HTMLElement).closest(`.${ANNOTATION_CLASS_NAME}`)
      ) {
        return true;
      }

      // this allows us to select text and register the selection event,
      // even when outside the text container or window
      window.addEventListener(
        "mouseup",
        async () => {
          // make sure to release the current annotation marker
          setCurrentAnnotationElement(null);

          await annotate(onAnnotationCreated);
        },
        {
          once: true,
        },
      );
    };
  }

  function resetCurrentAnnotationElement() {
    setCurrentAnnotationElement(null);
  }

  const currentHighlight =
    currentAnnotationElement &&
    highlighter?.current?.getHighlightForElement(currentAnnotationElement);
  const currentAnnotation =
    currentHighlight &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    highlightToAnnotationMap.current.get(currentHighlight.id!);

  return {
    annotate,
    annotateWithSelection,
    isHighlighted,
    currentAnnotationElement,
    currentAnnotation,
    removeCurrent,
    removeAnnotation,
    resetCurrentAnnotationElement,
  };
}
