import * as React from "react";

import { Button } from "../../../components/Button";
import { OpenPopoverIcon } from "../../../components/Icons";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/DropdownMenu";
import {
  RecipeFilterSortBy,
  RecipeFilterSortOrder,
} from "../../../utilities/filter";

export type Value = {
  order?: RecipeFilterSortOrder;
  by?: RecipeFilterSortBy;
};
interface OrderControlProps {
  value?: Value;
  onChange(value: Value): void;
}

const options: {
  value: Value["order"];
  label: string;
  asc: string;
  desc: string;
}[] = [
  {
    value: "addedAt",
    label: "Date added",
    asc: "Old → Recent",
    desc: "Recent → Old",
  },
  {
    value: "lastCookedAt",
    label: "Date last cooked",
    asc: "Old → Recent",
    desc: "Recent → Old",
  },
  {
    value: "website.name",
    label: "Publisher",
    asc: "A → Z",
    desc: "Z → A",
  },
  {
    value: "name",
    label: "Title",
    asc: "A → Z",
    desc: "Z → A",
  },
];

export const defaultValue: Value = {
  order: "addedAt",
  by: "desc",
};

const OrderControl = React.forwardRef<
  React.ElementRef<typeof DropdownMenu>,
  OrderControlProps
>(({ value = defaultValue, onChange }, ref) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          ref={ref}
          variant="outline"
          size="sm"
          className="w-full justify-between px-3 font-normal"
        >
          {options.find((item) => item.value === value.order)?.label}
          {", "}
          {value.by
            ? options.find((item) => item.value === value.order)?.[value.by]
            : null}
          <OpenPopoverIcon className="ml-1 h-4 w-4 shrink-0 stroke-[1.5]" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        side="bottom"
        className="w-[var(--radix-dropdown-menu-trigger-width)]"
      >
        <DropdownMenuGroup>
          <DropdownMenuLabel>Sort by</DropdownMenuLabel>
          {options.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.label}
              checked={option.value === value.order}
              onCheckedChange={(checked) => {
                if (checked) {
                  onChange({ ...value, order: option.value });
                }
                return;
              }}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Order by</DropdownMenuLabel>

          <DropdownMenuCheckboxItem
            checked={value.by === "asc"}
            onCheckedChange={(checked) => {
              if (checked) {
                onChange({ ...value, by: "asc" });
              }
              return;
            }}
          >
            {options.find((item) => item.value === value.order)?.asc}
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={value.by === "desc"}
            onCheckedChange={(checked) => {
              if (checked) {
                onChange({ ...value, by: "desc" });
              }
              return;
            }}
          >
            {options.find((item) => item.value === value.order)?.desc}
          </DropdownMenuCheckboxItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
});

OrderControl.displayName = "OrderControl";

export { OrderControl };
