import { v4 as uuid } from "uuid";
import { ViewDocument } from "db";

import { toast } from "../components/Toast";
import { getDb } from "../db";
import {
  filtersToObject,
  filtersToString,
  queryToRecipeFilters,
  RecipeFilters,
  recipeFiltersToQuery,
} from "../utilities/filter";

export async function addCollection(
  collection: Partial<ViewDocument>,
  onSuccess?: (collectionId?: string) => void,
) {
  const db = await getDb();

  try {
    const view = await db.views.insert({
      id: uuid(),
      title: collection.title ?? `Collection: ${Date.now().toString()}`,
      filter: collection.filter,
    });
    toast({ title: "Collection successfully added" });
    onSuccess?.(view.id);
    return view;
  } catch (err) {
    toast({ title: "Collection could not be added" });
    console.error("Collection could not be added", err);
  }
}

export async function updateCollection(
  collection: ViewDocument,
  collectionUpdate: {
    title?: string;
    filter?: RecipeFilters;
  },
  onSuccess?: () => void,
) {
  try {
    await collection.modify((currentCollection) => {
      const currentCollectionFilters = queryToRecipeFilters(
        filtersToObject(currentCollection.filter ?? ""),
      );

      const newFilter = {
        ...currentCollectionFilters,
        ...collectionUpdate.filter,
      };

      currentCollection.filter = filtersToString(
        recipeFiltersToQuery(newFilter),
      );
      currentCollection.title =
        collectionUpdate.title ?? currentCollection.title;

      return currentCollection;
    });
    onSuccess?.();
  } catch (err) {
    toast({ title: "Collection could not be updated" });
    console.error("Collection could not be updated", err);
  }
}

export async function deleteCollection(
  collection: ViewDocument,
  onSuccess?: () => void,
) {
  try {
    await collection.remove();
    toast({ title: "Collection successfully deleted" });
    onSuccess?.();
  } catch (err) {
    toast({ title: "Collection could not be deleted" });
    console.error("Collection could not be deleted", err);
  }
}
