import { AnnotationDocument } from "db";
import React, { useState } from "react";
import { Tooltip } from "@radix-ui/react-tooltip";

import { DeleteIcon, EditNoteIcon } from "../../../../../components/Icons";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/Popover";
import { Textarea } from "../../../../../components/Textarea";
import { Button } from "../../../../../components/Button";
import {
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/Tooltip";

export default function AnnotationContextMenu({
  annotation,
  onSaveNote,
  onRemove,
}: {
  annotation: AnnotationDocument | undefined | null | void;
  onSaveNote: (note: string | undefined) => Promise<void>;
  onRemove: () => Promise<boolean>;
}) {
  const [text, setText] = useState<string | undefined>(annotation?.note);
  return (
    <div
      className="absolute flex w-auto gap-[1px] rounded-md bg-background"
      style={{
        transform: "translateX(-50%) translateY(-110%)",
        left: "50%",
        top: "0",
      }}
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="secondary"
            size="iconSm"
            onClick={onRemove}
            className="rounded-br-none rounded-tr-none"
          >
            <DeleteIcon className="ml-[1px] h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="font-sans">Delete highlight</TooltipContent>
      </Tooltip>
      <Popover>
        <Tooltip>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <Button
                variant="secondary"
                size="iconSm"
                className="rounded-bl-none rounded-tl-none"
              >
                <EditNoteIcon className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent className="font-sans">Add note</TooltipContent>
        </Tooltip>
        <PopoverContent>
          <div className="w-full">
            <Textarea
              className="mb-4"
              onChange={(event) => setText(event.target.value)}
              placeholder="Add a note to your highlight…"
              value={text}
            ></Textarea>
            <div className="flex flex-row justify-end space-x-2">
              <PopoverClose asChild aria-label="Close">
                <Button variant="ghost" size="sm">
                  Cancel
                </Button>
              </PopoverClose>
              <PopoverClose asChild aria-label="Close">
                <Button onClick={() => onSaveNote(text)} size="sm">
                  Save
                </Button>
              </PopoverClose>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
